import { BiohubError, setBiohubAuthorizationToken } from "../../services/axios/BiohubApi";
import LoginService from "../../services/LoginService";
import { LoginState, LoginUserStatus } from "../reducers/loginReducer";
import { SystemThunk } from "../systemThunk";
import history from "../../navigation/history";
import { loadProfileInformation } from "./profileActions";
import { fetchCollections } from "./collectionsActions";
import { BiohubLocale, LocaleState } from "../reducers/localeReducer";
import { REHYDRATE } from "redux-persist/es/constants";
import { SystemState } from "../reducers/systemReducer";

// Action constants

export const LOG_IN_REQUEST = "LOG_IN_REQUEST";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAILURE = "LOG_IN_FAILURE";
export const RESET_ERROR = "RESET_ERROR";
export const LOG_OUT = "LOG_OUT";

// Action types
export type LoginAction =
  /** Action fired at the start of the login thunk. Should only set a loading flag in the reducer. */
  | {
      type: typeof LOG_IN_REQUEST;
    }
  /** Action fired at the end of the login thunk */
  | {
      type: typeof LOG_IN_SUCCESS;
      payload: {
        // It's not typical for an action to carry the entire state, but in this case a login success
        // will overwrite the entire state. Thus, the logic of assembling a login state object is the
        // thunk's problem.
        loginState: LoginState;

        //Leon: adding
        userId: string;
      };
    }
  /** Action fired when there's an error in the login thunk */
  | {
      type: typeof LOG_IN_FAILURE;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof LOG_OUT;
    }
  | {
      type: typeof RESET_ERROR;
    }
  | {
      type: typeof REHYDRATE;
      payload: SystemState;
    };

// Action functions

export function logIn(
  username: string,
  password: string,
  keepLoggedIn: boolean,
  nextPage?: string
): SystemThunk {
  return async (dispatch, getState) => {
    // This will set a loading state.
    dispatch({
      type: LOG_IN_REQUEST,
    });

    // Make the login request and obtain a token.
    const loginResult = await LoginService.login(username, password, keepLoggedIn);

    if (!loginResult.success) {
      dispatch({
        type: LOG_IN_FAILURE,
        payload: {
          error: loginResult.error,
        },
      });
      return;
    }

    // If successful, proceed to obtain the user's profile.
    // Note: There's the possibility that the user still has pending terms of use to accept, but
    // at this point that's not being enforced yet.
    const token: string = loginResult.data.token;

    // Now, set the token to ensure that subsequent calls to the api will include the token,
    // including the call to /profile.
    setBiohubAuthorizationToken(token);

    // Get the current profile.
    /*const profileResult = await ProfileService.getProfile(loginResult.data.userId);

    if (!profileResult.success) {
      dispatch({
        type: LOG_IN_FAILURE,
        payload: {
          error: profileResult.error,
        },
      });
      return;
    }*/

    dispatch({
      type: LOG_IN_SUCCESS,
      payload: {
        loginState: {
          token: loginResult.data.token,
          error: null,
          loading: false,
          status: LoginUserStatus.loggedIn,
          //profile: profileResult.data,
        },
        userId: loginResult.data.userId,
      },
    });

    history.push(`/${nextPage || "dashboard"}`.replace("//", "/"));

    const locale: LocaleState = getState().locale;

    // Additional dispatches to load other information.
    dispatch(loadProfileInformation(loginResult.data.userId, locale.localeCode, locale.changed));
    dispatch(fetchCollections());
  };
}

export function resetError(): LoginAction {
  return {
    type: RESET_ERROR,
  };
}

export function logOut(): SystemThunk {
  return async (dispatch) => {
    // Go to the landing page first. Otherwise, logging out will cause the current page to redirect
    // to the login page, and the login page will come back to where the user was before.
    history.push(`/`);
    dispatch({
      type: LOG_OUT,
    });
  };
}
