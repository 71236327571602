import React from "react";
import { TextField } from "./styles";

interface Props {
  type?: string;
  placeholder?: string;
  required?: boolean;
  value: string;
  onChange: (text: string) => void;
}

export default (props: Props) => {
  return (
    <>
      <TextField
        label={props.placeholder}
        type={props.type}
        required={props.required}
        variant="outlined"
        size="small"
        value={props.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.onChange(e.target.value);
        }}
      />
    </>
  );
};
