import {
  DirectClient,
  IndirectClient,
  Profile,
  ProfilePreferences,
  Role,
  UnitSystem,
  Address,
} from "biohub-model";
import BiohubApi, {
  BiohubResponse,
  BiohubUnit,
  biohubUnit,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";

function removeNullOnUndefinedAddress(address: Address): Address {
  if (address.complement === null) delete address.complement;
  if (address.cityCode === null) delete address.cityCode;
  return address;
}

async function getProfile(userId: string): Promise<BiohubResponse<Profile>> {
  try {
    const result = await BiohubApi.get(`/profiles/${userId}`);
    const profile = result.data.profile as Profile;
    if (profile.role !== Role.external) {
      profile.address = removeNullOnUndefinedAddress(profile.address);
    }
    return newBiohubSuccess(profile);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateProfile(
  profile: { id: string } & Partial<Profile>
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.put("/profiles/" + profile.id, {
      profile: profile,
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readDirectClient(directClientId: string): Promise<BiohubResponse<DirectClient>> {
  try {
    const result = await BiohubApi.get("/direct-clients/" + directClientId);
    const directClient = result.data["directClient"] as DirectClient;
    if (directClient.company !== undefined) {
      directClient.company.address = removeNullOnUndefinedAddress(directClient.company.address);
    }
    return newBiohubSuccess(directClient);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateDirectClient(
  directClient: { id: string } & Partial<DirectClient>
): Promise<BiohubResponse<BiohubUnit>> {
  //TODO: Backend implementation
  try {
    await BiohubApi.put("/direct-clients/" + directClient.id, directClient);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

/*async function readProfiles(profile: Profile): Promise<BiohubResponse<Array<Profile>>> {
  if (profile.directClientId === null) {
    return extractBiohubErrorResponse(Error("Can't read the direct client of that profile"));
  }
  const readDirectClientResult = await readDirectClient(profile.directClientId);
  if (!readDirectClientResult.success) {
    return readDirectClientResult;
  }

  const directClient = readDirectClientResult.data as DirectClient;
  return newBiohubSuccess(directClient.profiles);
}*/

async function createProfile(profile: Profile): Promise<BiohubResponse<BiohubUnit>> {
  try {
    const response = await BiohubApi.post("/profiles", { profile: profile });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function removeProfile(profileId: string): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.delete("/profiles/" + profileId);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readIndirectClients(
  directClientId: string
): Promise<BiohubResponse<Array<IndirectClient>>> {
  try {
    const response = await BiohubApi.get("/indirect-clients/" + directClientId);
    const indirectClients = response.data["indirectClients"] as Array<IndirectClient>;
    return newBiohubSuccess(indirectClients);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function addIndirectClient(
  indirectClient: IndirectClient
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.post("/indirect-clients", { indirectClient: indirectClient });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateIndirectClient(
  indirectClient: { id: string } & Partial<IndirectClient>
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.put("/indirect-clients/" + indirectClient.id, {
      indirectClient: indirectClient,
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function removeIndirectClient(indirectClientId: string): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.delete("/indirect-clients/" + indirectClientId);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateProfilePreferences(preferences: { id: string } & Partial<ProfilePreferences>) {
  try {
    await BiohubApi.put("/profile-preferences/" + preferences.id, {
      profilePreferences: preferences,
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

export default {
  getProfile,
  updateProfile,
  readDirectClient,
  updateDirectClient,
  //readProfiles,
  createProfile,
  removeProfile,
  readIndirectClients,
  addIndirectClient,
  removeIndirectClient,
  updateIndirectClient,
  updateProfilePreferences,
};
