import { Input, Releaser, ReleaserModel } from "biohub-model";
import { REHYDRATE } from "redux-persist/es/constants";
import { SET_INPUTS, SET_RELEASERS, SET_RELEASER_MODELS } from "../actions/collectionsActions";
import { SystemAction } from "../actions/systemActions";

export type CollectionsState = {
  releasers: Releaser[];
  releaserModels: ReleaserModel[];
  inputs: Input[];
};

const INITIAL_STATE: CollectionsState = {
  releasers: [],
  releaserModels: [],
  inputs: [],
};

export function collectionsReducer(state = INITIAL_STATE, action: SystemAction): CollectionsState {
  switch (action.type) {
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.collections,
          }
        : state;
    case SET_RELEASERS:
      return {
        ...state,
        releasers: action.payload.releasers,
      };
    case SET_RELEASER_MODELS:
      return {
        ...state,
        releaserModels: action.payload.releaserModels,
      };
    case SET_INPUTS:
      return {
        ...state,
        inputs: action.payload.inputs,
      };
    default:
      return state;
  }
}
