/**
 * Português Brasileiro (pt-BR)
 */
const messagesMap: { [key: string]: string } = {
  "app.title": "bioHUB",
  // --- Localização / idiomas ---
  ////"language.name": "Português (Brasil)",
  "language.defaultRegion": "BR",
  // --- Página de login ---
  "login.title.login": "Logar",
  "login.form.email": "Email",
  "login.form.password": "Senha",
  // --- Overlays do mapa ---
  "map.overlays.weather.temperature": "Temperatura",
  "map.overlays.weather.pressure": "Pressão",
  "map.overlays.weather.humidity": "Umidade",
  "map.overlays.weather.wind": "Vento",
  "map.overlays.weather.windSpeed": "Velocidade do vento",
  "map.overlays.weather.windDirection": "Direção do vento",
  "map.overlays.weather.percentageClouds": "Nuvens",
  "map.overlays.weather.searchTime": "Horário da busca",
  "map.overlays.weather.visibility": "Visibilidade",
  "map.overlays.weather.sunrise": "Nascer do sol",
  "map.overlays.weather.sunset": "Por-do-sol",
  "map.overlays.weather.timezone": "Fuso-horário",
  // --- Textos a serem usados de maneira geral no app (por exemplo, em botões) ---
  "generic.ok": "OK",
  "generic.cancel": "Cancelar",
  "generic.close": "Fechar",
  "generic.yes": "Sim",
  "generic.no": "Não",
  "generic.next": "Próximo",
  "generic.previous": "Anterior",
  "generic.continue": "Continuar",
  "generic.closeConfirmation.title": "Confirmação",
  "generic.closeConfirmation.discardChangesAndClose": "Descartar alterações e fechar?",
  "generic.N/A": "N/A",

  // --- Textos a serem usados em erros ---
  "generic.error.unknown": "Ocorreu um erro.",
  "info.termError": "Sua mensagem não pôde ser enviada no momento",

  //-- Main page info --//
  "info.pageTitle": "bioHUB - NCB",
  "info.slogan": "Preciso por Natureza",
  "info.title": "O primeiro e único HUB \nde tecnologias para \nControle Biológico de Precisão",
  "info.description": "Em Breve!",
  "info.newsletter": "Assine nossa Newsletter",
  "info.about": "bioHUB por NCB - Sistemas Embarcados",
  "info.copyright": "2021 ⓒ bioHUB - NCB. Todos os direitos reservados.",
  "info.address":
    "Estrada Doutor Altino Bondensan, 500 PQTEC - CE1 - Sala 207 - Eugênio de Melo, São José dos Campos - SP, CEP: 147-016",
  "info.email": "contato@ncb.ind.br",
  "info.phone": "(11) 98371-9738",
  "info.terms":
    "Ao aceitar o presente termo, o usuário consente e concorda que a empresa NCB, doravante denominada Controlador, tome decisões referentes ao tratamento de seus dados pessoais, bem como realize o tratamento de seus dados pessoais, envolvendo operações como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração. O Controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, caso seja necessário para as finalidades listadas neste termo, observados os princípios e garantias estabelecidas pela Lei nº 13.709.",
  "info.summary": "Resumo",
  "info.register.information": "Informações de registro",
  "info.register.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.legal.information": "Informações do representante legal",
  "info.legal.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.plans.information": "Informações do plano",
  "info.amount": "Quantidade",
  "info.plan": "Plano",
  "info.mode": "Tipo",
  "info.price": "Preço",
  "info.per.year": "Por Ano",
  "info.total": "Total",
  "info.users": "Usuários",
  "info.preregistrations": "Pré-Registros",
  "info.preregistration": "Pré-Registro",
  "info.preregistrations.viewdetails": "Essas são as informações desse pré-registro.",
  "info.contract": "Contrato",
  "info.add.new": "Insira os dados abaixo para adicionar um novo usuário.",
  "info.clients": "Clientes",
  "info.menuOptions": "Opções do menu",

  //-- Pages --//
  "info.subtitleTerms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.pageTerms":
    "Ao aceitar o presente termo, o usuário consente e concorda que a empresa NCB, doravante denominada Controlador, tome decisões referentes ao tratamento de seus dados pessoais, bem como realize o tratamento de seus dados pessoais, envolvendo operações como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração. O Controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, caso seja necessário para as finalidades listadas neste termo, observados os princípios e garantias estabelecidas pela Lei nº 13.709.",
  "info.subtitlePrivacy": "Lorem ipsum dolor sit amet consecter",
  "info.privacy":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleSupport": "Lorem ipsum dolor sit amet consecter",
  "info.support":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleFaq": "Lorem ipsum dolor sit amet consecter",
  "info.faq":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion1": "Question 1 ?",
  "info.faqQuestion1Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion2": "Question 2 ?",
  "info.faqQuestion2Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion3": "Question?",
  "info.faqQuestion3Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleWhy": "Lorem ipsum dolor sit amet consecter",
  "info.why":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleResources": "Lorem ipsum dolor sit amet consecter",
  "info.resources":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",

  "info.aboutTitle": "O que é ",
  "info.aboutNCB": "NCB?",
  "info.aboutDescription":
    "bioHub é uma plataforma digital holística que permite o gerenciamento eficaz de pragas e doenças por meio de drones e softwares com foco em inteligência de negócios. \n\n Somos uma empresa pioneira no uso de controle biológico de precisão.",
  "info.aboutVisionTitle": "Nossa Visão",
  "info.aboutVisionSub": "A realidade que se busca",
  "info.aboutVisionText":
    "Transformar o setor agrícola, promovendo a gestão de dados e a aplicação de tecnologia e inovação sustentável para a criação de estratégias e o uso assertivo do controle biológico. Em três anos, queremos que a Bi All seja a referência na simbiose entre agricultura, ciência e tecnologia, após ter operado três milhões de hectares de lavouras.",
  "info.aboutMissionTitle": "Nossa Missão",
  "info.aboutMissionSub": "A razão de ser",
  "info.aboutMissionText":
    "Catalisar a natureza através da digitalização de dados que permite criar estratégias para a tomada de decisões garantindo a melhor gestão dos recursos. Fornecer soluções em ecossistemas agrícolas por meio do controle biológico de precisão.",
  "info.aboutValues": "Nossos Valores",
  "info.contactTitle": "Fale Conosco",
  "info.contactDescription":
    "Preencha seus dados no formulário abaixo e aguarde. Entraremos em contato assim que possível!",
  "info.termTitle": "O plano bioMAPS",
  "info.coin": "R$",
  "info.termPriceMonth": "por mês / por aparelho",
  "info.termPriceYear": "por ano / por aparelho (15% de desconto)",
  "info.termSpecifications": "Especificações: ",
  "info.termSpecificationsDesc":
    "É o primeiro planejador de missão da DRONES com foco no controle de precisão biológica. É possível planejar rotas de qualquer lugar do mundo através da nuvem e sincronizadas com um aplicativo móvel para fazer lançamentos de insumos biológicos 100% rastreados e controlados por GPS.",
  "info.termClassifications": "Classificações: ",
  "info.termClassificationsDesc":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis curae cursus per, morbi neque viverra nam nascetur mauris metu consequat quis.",
  "info.termChoose": "Escolher Plano",
  "info.introducing": "Apresentando",
  "info.cameraQuality": "Qualidade da câmera",
  "info.flightTime": "Tempo de vôo",
  "info.capacity": "Capacidade",
  "info.minutes": "minutos",
  "info.verificationTitle": "Verificação de e-mail",
  "info.verificationDescription":
    "A verificação requer um endereço de e-mail válido. \nEnviaremos um código de confirmação para o endereço de e-mail informado para iniciarmos o processo de validação. \nSe o endereço informado abaixo estiver incorreto, clique no ícone e altere seus dados.",
  "info.emailAddress": "Endereço de e-mail",
  "info.verificationCodeDescription": "Informe o código enviado para seu e-mail:",
  "info.confirmationCode": "Código de verificação",
  "info.newPassword": "Nova Senha",
  "info.newPasswordDescription": "Por favor, defina uma nova senha para acessar nossa plataforma.",
  "info.newPasswordDetails": "Informe a nova senha",
  "info.newPasswordConfirm": "Confirme a nova senha",

  "prod.biocoteDescription":
    "É um liberador de xícara biodegradável com massa de cotesia utilizado principalmente no controle populacional de lagartas. A cana-de-açúcar é a principal cultura tratada. (Desenvolvimento exclusivo do Biocontrol). 100% operacional e comercial. Sua versão de massas únicas parasitadas foi congelada por nós não podemos continuar os testes de campo, retomaremos a partir de janeiro de 2021.",
  "prod.usageSugarcane": "Cana de açucar",
  "prod.usageForest": "Floresta",
  "prod.usageCotton": "Algodão",
  "prod.usageCorn": "Milho",
  "prod.usageSoybeans": "Soja",

  //-- Landing Page --//
  "page.title": "Digitalize seu campo, \no primeiro passo para \no futuro da agricultura",
  "page.description":
    "bioHUB é uma plataforma digital holística que permite o manejo eficaz de pragas e doenças através de drones e softwares focados em inteligência empresarial.",
  // Sections
  "section.about.title": "Nossos produtos",
  "section.about.category": "Software",
  "section.about.info.title": "Sobre o bioHUB",
  "section.about.info.subtitle": "Sistema de gestão de controle biológico de precisão",
  "section.about.info.description":
    "É uma plataforma de nuvem integrada que auxilia toda a cadeia de controle biológico a monitorar pragas, liberar com precisão, gerar relatórios e monitorar a tomada de decisões.",
  "section.biomaps.title": "Sobre BioMAPS:",
  "section.biomaps.subtitle": "Planificador de missões de vôo",
  "section.biomaps.description":
    "É o primeiro planejador de missão para DRONES focado em controle biológico de precisão. É possível planejar rotas de qualquer lugar do mundo.",
  "section.products.category": "Hardware",
  "section.prices.category": "Preços",
  "section.prices.title": "O plano certo para o seu negócio",
  "section.prices.description":
    "Temos vários planos poderosos para mostrar o seu negócio e ser descoberto como um empreendedor criativo.",
  "section.clients.category": "Clientes",
  "section.clients.title": "O que nossos clientes têm a nos dizer",
  "section.partners.category": "Parceiros",

  //-- Dashboard Information --//
  "dashboard.welcome": "Bem-vindo(a) de volta, \n",
  "dashboard.clients": "Clientes",
  "dashboard.users": "Usuários",
  "dashboard.total.users": "Total de Usuários",
  "dashboard.total.clients": "Total de Clientes",
  "dashboard.total.drones": "Total de Drones",
  "dashboard.total.projects": "Total de Projetos",
  "dashboard.total.sales": "Total de Vendas",
  "dashboard.total.area": "Total de Hectares Cobertos",
  "dashboard.total.yearMonthLegend": "Mês / Ano",
  "dashboard.hectares.covered": "Hectares Cobertos",
  "dashboard.new.company": "Novas empresas",
  "dashboard.flight.client": "Vôos por cliente",
  "dashboard.coverage.graph": "Gráfico de área de cobertura",
  "dashboard.scale": "Janelamento",
  "dashboard.graphmodeview": "Visualizar por",
  "dashboard.filter": "Filtro",
  "dashboard.time.30minutes": "30 minutos",
  "dashboard.time.1hour": "1 hora",
  "dashboard.time.8hours": "8 horas",
  "dashboard.time.1day": "1 dia",
  "dashboard.time.1week": "1 semana",
  "dashboard.view.general": "-",
  "dashboard.view.profile": "Perfil",
  "dashboard.view.client": "Cliente",
  "dashboard.view.drone": "Drone",

  //-- Menu Information --//
  "menu.about": "Sobre a NCB",
  "menu.products": "Produtos",
  "menu.pricing": "Preços",
  "menu.contact": "Contato",
  "menu.privacy": "Política de Privacidade",
  "menu.terms": "Termos e Condições",
  "menu.title": "Empresa",
  "menu.support": "Suporte",
  "menu.faq": "FAQ's",
  "menu.resources": "Recursos",
  "menu.why": "Por que bioHUB?",
  "menu.home": "Início",
  "menu.biomaps": "bioMAPS",
  "menu.profile": "Pefil",
  "menu.plans": "Planos",
  "menu.settings": "Configurações",
  "menu.open.project": "Abrir Projeto",
  "menu.new.project": "Novo Projeto",
  "menu.new.monitoring": "Novo Monitoramento",
  "menu.flight.monitoring": "Monitorar Vôo",
  "menu.trap.monitoring": "Monitorar Armadilha",
  "menu.biomapsInfo":
    "O BioMAP visualiza as áreas onde os controladores biológicos são liberados por meio do planejador de missão.",
  "menu.licenses": "Licenças",
  "menu.traps": "Armadilhas",

  //-- Generic Actions --//
  "action.login": "Entrar",
  "action.signUp": "Inscreva-se",
  "action.subscribe": "Assinar",
  "action.register": "Cadastrar",
  "action.makeRegistration": "Faça aqui seu pré cadastro!",
  "action.success": "Sucesso",
  "action.error": "Desculpe",
  "action.msgSuccess":
    "Seu pré-cadastro foi realizado com sucesso em nossa base! \nEm breve entraremos em contato ✓",
  "action.msgError":
    "Tivemos um problema ao realizar seu pré-cadastro! Por favor, tente novamente em instantes.",
  "action.back": "voltar",
  "action.getStarted": "Iniciar",
  "action.learnMore": "Saiba mais",
  "action.more": "Mais informações...",
  "action.keep.connected": "Mantenha-me conectado",
  "action.login.message": "Informe seus dados para fazer login",
  "action.login.msgError":
    "Erro ao fazer login. Por favor, confira seus dados ou tente novamente em instantes.",
  "action.continue": "Continuar",
  "action.accept": "Estou de acordo",
  "action.indicator": "Cadastrar-se como: ",
  "action.signup.company": "Pessoa Jurídica",
  "action.signup.single": "Pessoa Física",
  "action.accept.signup.terms": "com os termos gerais da lei de proteção de dados LGPD.",
  "action.pay.now": "Pagar agora",
  "action.add.new": "Adicionar novo",
  "action.edit": "Editar",
  "action.edit.picture": "Alterar foto",
  "action.remove": "Remover",
  "action.save": "Salvar",
  "action.cancel": "Cancelar",
  "action.undo": "Desfazer",
  "action.finish": "OK",
  "action.settings": "Configurações",
  "action.income": "Renda",
  "action.send": "Enviar",
  "action.confirm": "Confirmar",
  "action.sendVerification": "Enviar código de verificação",
  "action.saveData": "Salvar dados",

  //-- Placeholders --//
  "placeholder.profile": "Perfil",
  "placeholder.email": "E-mail",
  "placeholder.name": "Nome",
  "placeholder.phone": "Telefone",
  "placeholder.company": "Empresa",
  "placeholder.data.collect": "Como conheceu a NCB?",
  "placeholder.password": "Senha",
  "placeholder.search": "Buscar",
  "placeholder.iva": "IVA",
  "placeholder.company.name": "Nome",
  "placeholder.zip": "CEP",
  "placeholder.address": "Endereço",
  "placeholder.number": "Número",
  "placeholder.complement": "Complemento",
  "placeholder.country": "País",
  "placeholder.state.department": "Estado",
  "placeholder.city": "Cidade",
  "placeholder.comercial.phone": "Telefone Comercial",
  "placeholder.aviation.reg": "Registro de Aviação",
  "placeholder.agriculture.reg": "Registro de Agricultura",
  "placeholder.role": "Função",
  "placeholder.document": "Documento",
  "placeholder.rg": "RG",
  "placeholder.crea": "CREA",
  "placeholder.ie": "I.E.",
  "placeholder.im": "I.M.",
  "placeholder.trade.name": "Nome Comercial",
  "placeholder.type": "Tipo de usuário",
  "placeholder.contact": "Contato",
  "placeholder.description": "Bio",
  "placeholder.message": "Mensagem",
  "placeholder.project": "Projeto",
  "placeholder.area": "Área",
  "placeholder.aircraft": "Aeronave",

  "profile.account": "Minha Conta",
  "profile.profile": "Meu Perfil",

  "profile.selectedUser": "selecionado",

  //-- Options --//
  "option.preferences.generalinfo": "Informações gerais",
  "option.preferences.unitsystem": "Sistema de unidades",
  "option.preferences.twofactorauth": "Autenticação em dois fatores",
  "option.preferences.sexagesimalcoordinates": "Usar Coordenadas Sexagesimais",
  "option.preferences.separatelybatteries": "Mostrar baterias separadamente",
  "option.preferences.metric": "Métrico",
  "option.preferences.imperial": "Imperial",
  "option.search.engine": "Mecanismos de Busca",
  "option.facebook": "Facebook",
  "option.linkedin": "Linkedin",
  "option.recommendation": "Recomendações",
  "option.email.marketing": "E-mail Marketing",
  "option.events": "Eventos",
  "option.others": "Outros",
  "option.which": "Quais?",

  //-- Map --//
  "map.title": "Mapa",
  "map.defaultRegion": "BR",
  "map.editMode.addPolygon":
    "Toque no mapa para inserir um ponto. Mantenha um marcador pressionado para mudar sua posição.",
  "map.editMode.error.theePointsMinimum": "Por favor, insira ao menos três pontos.",
  "map.editMode.error.interesection":
    "A área desenhada possui uma ou mais intersecções. Por favor, desenhe um polígono simples.",
  "map.removeArea": "Deletar esta area?",
  "map.newArea": "Nova área",
  "map.loadingList": "carregando...",
  "map.editPolygonPoints": "Editar pontos",
  "map.editAreaProperties": "Editar área",
  "map.deleteArea": "Deletar área",
  "map.importAreas.importAreasAction": "Importar áreas",
  "map.newArea.createAreaModalTitle": "Criar nova área",
  "map.newArea.importAreaModalTitle": "Importar áreas",
  "map.newArea.areaName": "Nome da área",
  "map.newProject.title": "Criar projeto",
  "map.newProject.projectName": "New project",
  "map.newProject.optionalImportKml": "Importar arquivo",
  "map.newProject.kmlFileChoose": "Abrir...",
  "map.newProject.releaser": "Liberador",
  "map.newProject.input": "Insumo",
  "map.newProject.releaseRate": "Taxa de liberação",
  "map.newProject.instantaneousReleaseRate": "Taxa de liberação instantânea",
  "map.type.roadmap": "Mapa",
  "map.type.satellite": "Satélite",
  "map.type.hybrid": "Híbrido",
  "map.areasAmountSing": "{amount} área",
  "map.areasAmountPl": "{amount} áreas",

  //-- Route planning --//
  "routePlanning.param.areaPadding": "Recuo da borda",
  "routePlanning.param.flightHeight": "Altura de voo",
  "routePlanning.param.flightSpeed": "Velocidade de voo",
  "routePlanning.param.trackWidth": "Largura de faixa",
  "routePlanning.param.considerRelief": "Considerar relevo do terreno",

  "routePlanning.param.waypoint.height": "Altura",
  "routePlanning.param.waypoint.curveRadius": "Raio da curva",
  "routePlanning.param.waypoint.speed": "Velocidade",
  "routePlanning.param.waypoint.orientation": "Orientação",

  "routePlanning.param.waypoint.actions": "Ações",
  "routePlanning.param.waypoint.action.enableEquipment": "Habilitar Equipamento",
  "routePlanning.param.waypoint.action.disableEquipment": "Desativar Equipamento",
  "routePlanning.param.waypoint.action.none": "Nenhuma",

  //-- LANGUAGES --//
  language: "Idioma",
  "language.pt": "Português - Brasileiro",
  "language.es": "Espanhol",
  "language.en": "Inglês",
  "language.fr": "Francês",
  "language.name": "Português (Brasil)",
  "language.region": "BR",
  "language.decimalSeparator": ",",

  //- Reports -//
  "report.issueDate": "Data de Emissão:",
  "report.area": "Área",
  "report.plannedFlight": "Vôo Planejado",
  "report.details": "Detalhes",
  "report.dateHour": "Data e Hora:",
  "report.drone": "Drone:",
  "report.manufacturer": "Fabricante:",
  "report.anac": "ANAC:",
  "report.releasedProduct": "Produto Liberado:",
  "report.releasing": "Liberador",
  "report.bandWidth": "Largura da Faixa",
  "report.plannedSpeed": "Velocidade Planejada",
  "report.releaseRate": "Taxa de Liberação",
  "report.realFlight": "Vôo Real",
  "report.flightTime": "Tempo de Vôo",
  "report.averageReleaseRate": "Taxa de Liberação Média",
  "report.averageSpeed": "Velocidade Média",
  "report.totalReleased": "Total Liberado",
  "report.totalArea": "Área Total",
  "report.traps": "Armadilhas",
  "report.serialNumber": "Número de série",
  "report.coveredHectares": "Hectares cobertos",
  "report.dateMeasurament": "Data Ultima Medição",
  "report.specie": "Espécie",
  "report.adultsNumber": "Número de Adultos",
  "report.noAvailable": "Nenhum item disponível",
  "report.company": "Empresa:",
  "report.mapRecord": "Registro MAPA:",
  "report.contractor": "Contratante:",
  "report.property": "Propriedade:",
  "report.localization": "Localização:",
  "report.county": "Município:",
  "report.uf": "UF:",
  "report.doc": "CNPJ/CPF:",
  "report.serviceType": "Tipo de serviço:",
  "report.product": "Produto",
  "report.formulation": "Formulação",
  "report.dosage": "Dosagem",
  "report.toxicClass": "Classe Toxic.",
  "report.adjuvant": "Adjuvante",
  "report.culture": "Cultura: ",
  "report.volume": "Volume (litros ou kg/ha):",
  "report.others": "Outros: ",
  "report.agronomicalRecipe": "Receituário Agronomico n:",
  "report.issuedOn": "Emitido em:",
  "report.basicParameter": "Parametros Básicos de Aplicação",
  "report.maxTemperature": "Temperatura Máx.=",
  "report.relativeUnitMin": "Unidade Relativa Min.=",
  "report.equipment": "Equipamento=",
  "report.model": "Modelo=",
  "report.type": "Tipo=",
  "report.angle": "Ângulo=",
  "report.flightHeight": "Altura de vôo=",
  "report.bandwidth": "Largura da faixa(m)=",
  "report.date": "Data",
  "report.signatureCREA": "Nome, assinatura e CREA do profissional",
  "report.weatherConditions": "Condições Meteorológicas na Aplicação",
  "report.morning": "Matutino",
  "report.evening": "Vespertino",
  "report.applicationStart": "Início da aplicação: horas e término: horas",
  "report.initial": "Inicial",
  "report.final": "Final",
  "report.temperature": "Temperatura",
  "report.relativeHumidity": "Umid. Relativa",
  "report.aircraftPrefixe": "Prefixo - Aeronave:",
  "report.dgpsUse": "Uso do DGPS: Sim ou Não",
  "report.windSpeed": "Veloc. Vento",
  "report.trackCoordinates": "Coordenadas da Pista:",
  "report.observation": "Observações:",
  "report.signatureEng": "Nome, assinatura e CREA do Eng. Agronomo:",
  "report.print": "Imprimir",

  // Pre-registration research
  "preregistration.interest.question":
    "O que você acha que é o mais importante no\nControle Biológico de Precisão?",
  "preregistration.interest.financial": "Financeiro",
  "preregistration.interest.financial.cost": "Custo de operação",
  "preregistration.interest.financial.savings": "Economia de insumos",
  "preregistration.interest.financial.pest": "Redução de pragas",
  "preregistration.interest.financial.production": "Aumento de produção",

  "preregistration.interest.sustainability": "Sustentabilidade",
  "preregistration.interest.sustainability.carbonfootprint": "Redução da pegada de carbono",
  "preregistration.interest.sustainability.pesticides": "Redução de pesticidas",
  "preregistration.interest.sustainability.environment": "Meio ambiente",
  "preregistration.interest.sustainability.water": "Economia de água",

  "preregistration.interest.reliability": "Confiabilidade",
  "preregistration.interest.reliability.precision": "Precisão",
  "preregistration.interest.reliability.releasingrate": "Taxa de liberação",
  "preregistration.interest.reliability.tracking": "Rastreamento de rota",
  "preregistration.interest.reliability.coverage": "Cobertura de área",
};

export default messagesMap;
