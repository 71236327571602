import { SystemThunk } from "../systemThunk";
import { Input, Releaser, ReleaserModel } from "biohub-model";
import CollectionsService from "../../services/CollectionsService";

// Action constants

export const SET_RELEASERS = "SET_RELEASERS";
export const SET_RELEASER_MODELS = "SET_RELEASER_MODELS";
export const SET_INPUTS = "SET_INPUTS";

// Action types
export type CollectionsAction =
  | {
      type: typeof SET_RELEASERS;
      payload: {
        releasers: Releaser[];
      };
    }
  | {
      type: typeof SET_RELEASER_MODELS;
      payload: {
        releaserModels: ReleaserModel[];
      };
    }
  | {
      type: typeof SET_INPUTS;
      payload: {
        inputs: Input[];
      };
    };

// Action functions

export function fetchCollections(): SystemThunk {
  return async (dispatch) => {
    const releasers = await CollectionsService.readReleasers();
    const releaserModels = await CollectionsService.readReleaserModels();
    const inputs = await CollectionsService.readInputs();

    if (releasers.success) {
      dispatch({
        type: SET_RELEASERS,
        payload: {
          releasers: releasers.data,
        },
      });
    } else {
      console.warn("Failed to fetch releasers!");
      console.warn(releasers.error);
    }

    if (releaserModels.success) {
      dispatch({
        type: SET_RELEASER_MODELS,
        payload: {
          releaserModels: releaserModels.data,
        },
      });
    } else {
      console.warn("Failed to fetch releaser models!");
      console.warn(releaserModels.error);
    }

    if (inputs.success) {
      dispatch({
        type: SET_INPUTS,
        payload: {
          inputs: inputs.data,
        },
      });
    } else {
      console.warn("Failed to fetch inputs!");
      console.warn(inputs.error);
    }
  };
}
