/**
 * Français (fr)
 */
const messagesMap: { [key: string]: string } = {
  //-- Page Information --//
  "info.pageTitle": "bioHUB - NCB",
  "info.slogan": "Précis par Nature",
  "info.title":
    "Le premier et le seul HUB \nde technologies pour le \nContrôle Biologique de Précision",
  "info.description": "Prochainement!",
  "info.newsletter": "Inscrivez-vous à notre newsletter",
  "info.about": "bioHUB par NCB - Sistemas Embarcados",
  "info.copyright": "2021 ⓒ bioHUB - NCB. Tous droits réservés.",
  "info.address":
    "Estrada Doutor Altino Bondensan, 500 PQTEC - CE1 - Sala 207 - Eugênio de Melo, São José dos Campos - SP, CEP: 147-016",
  "info.email": "contato@ncb.ind.br",
  "info.phone": "(11) 98371-9738",
  "info.terms":
    "En acceptant les présentes conditions, l'utilisateur consent et accepte que la société NCB, ci-après dénommée Responsable du traitement, prenne des décisions concernant le traitement de ses données personnelles, ainsi que le traitement de ses données personnelles, impliquant des opérations telles que celles-ci. se référant à la collecte, la production, la réception, la classification, l'utilisation, l'accès, la reproduction, la transmission, la distribution, le traitement, l'archivage, le stockage, l'élimination, l'évaluation ou le contrôle des informations, la modification, la communication, le transfert, la diffusion ou l'extraction. Le Responsable du traitement est autorisé à partager les données personnelles du Titulaire avec d'autres agents de traitement de données, si nécessaire aux fins énumérées dans ce terme, sous réserve des principes et garanties établis par la loi n° 13.709.",
  "info.summary": "Résumé",
  "info.register.information": "Information d'inscription",
  "info.register.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.legal.information": "Informations du représentant légal",
  "info.legal.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.plans.information": "Informations sur le plan",
  "info.amount": "La quantité",
  "info.plan": "Plat",
  "info.mode": "Mode",
  "info.price": "Valeur",
  "info.per.year": "Par An",
  "info.total": "Le total",
  "info.users": "Utilisateurs",
  "info.preregistrations": "Pré-inscription",
  "info.preregistration": "Pré-inscription",
  "info.preregistrations.viewdetails": "Ce sont les informations de cette pré-inscription.",
  "info.contract": "Contracter",
  "info.add.new": "Entrez les données ci-dessous pour ajouter un nouvel utilisateur.",
  "info.clients": "Les clientes",
  "info.termError": "Sua mensagem não pôde ser enviada no momento",
  "info.menuOptions": "Options de menu",

  "info.aboutTitle": "Qu'est-ce que le ",
  "info.aboutNCB": "NCB?",
  "info.aboutDescription":
    "bioHub est une plate-forme numérique holistique qui permet une gestion efficace des parasites et des maladies grâce à des drones et des logiciels en mettant l'accent sur la veille économique. \n\n Nous sommes un pionnier dans l'utilisation de la lutte biologique de précision.",
  "info.aboutVisionTitle": "Notre vision",
  "info.aboutVisionSub": "La réalité recherchée",
  "info.aboutVisionText":
    "Transformer le secteur agricole en favorisant la gestion des données et l'application de technologies et d'innovations durables pour la création de stratégies et l'utilisation affirmée de la lutte biologique. En trois ans, nous voulons que Bi All soit la référence dans la symbiose entre agriculture, science et technologie, après avoir exploité trois millions d'hectares de cultures.",
  "info.aboutMissionTitle": "Notre mission",
  "info.aboutMissionSub": "La raison d'être",
  "info.aboutMissionText":
    "Catalysez la nature grâce à la numérisation des données qui vous permet de créer des stratégies de prise de décision, assurant la meilleure gestion des ressources. Apporter des solutions aux écosystèmes agricoles grâce à la lutte biologique de précision.",
  "info.aboutValues": "Nos valeurs",
  "info.contactTitle": "Contactez-nous",
  "info.contactDescription":
    "Remplissez vos coordonnées dans le formulaire ci-dessous et attendez. Nous vous contacterons dès que possible!",
  "info.termTitle": "Le plan bioMAPS",
  "info.coin": "€",
  "info.termPriceMonth": "par mois / par appareil",
  "info.termPriceYear": "par an / par appareil (15% remise)",
  "info.termSpecifications": "Caractéristiques:",
  "info.termSpecificationsDesc":
    "Il s'agit du premier planificateur de mission de DRONES axé sur le contrôle de précision biologique. Il est possible de planifier des itinéraires de n'importe où dans le monde via le cloud et synchronisé avec une application mobile pour lancer des bios 100% suivis et contrôlés par GPS.",
  "info.termClassifications": "Évaluations: ",
  "info.termClassificationsDesc":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis curae cursus per, morbi neque viverra nam nascetur mauris metu consequat quis.",
  "info.termChoose": "Choisissez le forfait",
  "info.introducing": "Présentation",
  "info.cameraQuality": "Qualité de la caméra",
  "info.flightTime": "Temps de vol",
  "info.capacity": "Capacité",
  "info.minutes": "minutes",
  "info.verificationTitle": "Vérification de l'e-mail",
  "info.verificationDescription":
    "La vérification nécessite une adresse e-mail valide. \nNous enverrons un code de confirmation à l'adresse e-mail que vous avez saisie afin de lancer le processus de validation. \nSi l'adresse indiquée ci-dessous est incorrecte, cliquez sur l'icône et modifiez vos coordonnées.",
  "info.emailAddress": "Adresse e-mail",
  "info.verificationCodeDescription": "Entrez le code envoyé à votre email :",
  "info.confirmationCode": "Code de vérification",
  "info.newPassword": "Nouveau mot de passe",
  "info.newPasswordDescription":
    "Veuillez définir un nouveau mot de passe pour accéder à notre plateforme.",
  "info.newPasswordDetails": "Entrez le nouveau mot de passe",
  "info.newPasswordConfirm": "Confirmez votre nouveau mot de passe",

  "prod.biocoteDescription":
    "Il s'agit d'un libérateur de gobelets biodégradable avec masse cothesia utilisé principalement dans le contrôle des populations de chenilles. La canne à sucre est la principale culture traitée. (Développement exclusif de Biocontrol). 100% opérationnel et commercial. Votre version de pâtes uniques parasitées a été congelée car nous ne pouvons pas continuer les tests sur le terrain, nous reprendrons à partir de janvier 2021.",
  "prod.usageSugarcane": "Canne à sucre",
  "prod.usageForest": "Forêt",
  "prod.usageCotton": "Coton",
  "prod.usageCorn": "Maïs",
  "prod.usageSoybeans": "Soybeans",

  //-- Landing Page --//
  "page.title": "Digitalisez votre champ, \nle premier pas vers \nl'avenir de l'agriculture",
  "page.description":
    "bioHUB est une plateforme numérique holistique qui permet une gestion efficace des ravageurs et des maladies grâce à des drones et des logiciels axés sur l'intelligence commerciale.",
  // Sections
  "section.about.title": "Nos produits",
  "section.about.category": "Software",
  "section.about.info.title": "Sur bioHUB",
  "section.about.info.subtitle": "Système de gestion de contrôle biologique de précision",
  "section.about.info.description":
    "Une plate-forme cloud intégrée qui aide chaque unité de contrôle biologique à surveiller les ravageurs, à libérer des agents biologiques avec précision, à générer des rapports et à surveiller la prise de décision.",
  "section.biomaps.title": "Sur BioMAPS:",
  "section.biomaps.subtitle": "Planificateur de mission de vol",
  "section.biomaps.description":
    "Le premier planificateur de mission pour DRONES axé sur le contrôle biologique de précision. Il est possible de planifier des itinéraires de n'importe où dans le monde.",
  "section.products.category": "Hardware",
  "section.prices.category": "Tarification",
  "section.prices.title": "Le bon plan pour votre entreprise",
  "section.prices.description":
    "Nous avons plusieurs plans puissants pour mettre en valeur votre entreprise et être découvert en tant qu'entrepreneur créatif.",
  "section.clients.category": "Les Clients",
  "section.clients.title": "Ce que nos clients ont à dire",
  "section.partners.category": "Les Partenaires",

  //-- Dashboard Information --//
  "dashboard.welcome": "Content de te revoir, \n",
  "dashboard.clients": "Les clientes",
  "dashboard.users": "Utilisateurs",
  "dashboard.total.users": "Nombre Total d'Utilisateurs",
  "dashboard.total.clients": "Nombre Total de Clients",
  "dashboard.total.drones": "Nombre Total de Drones",
  "dashboard.total.projects": "Nombre Total de Projets",
  "dashboard.total.sales": "Total des Ventes",
  "dashboard.total.area": "Nombre Total d'Hectares Couverts",
  "dashboard.total.yearMonthLegend": "Mois / Année",
  "dashboard.hectares.covered": "Hectares Couverts",
  "dashboard.new.company": "Nouvelles entreprises",
  "dashboard.flight.client": "Vols par client",
  "dashboard.coverage.graph": "Graphique de la zone de couverture",
  "dashboard.scale": "Échelle",
  "dashboard.graphmodeview": "Vu par",
  "dashboard.filter": "Filtre",
  "dashboard.time.30minutes": "30 minutes",
  "dashboard.time.1hour": "1 heure",
  "dashboard.time.8hours": "8 heures",
  "dashboard.time.1day": "1 jour",
  "dashboard.time.1week": "1 semaine",
  "dashboard.view.general": "-",
  "dashboard.view.profile": "Profil",
  "dashboard.view.client": "Client",
  "dashboard.view.drone": "Drone",

  //-- Menu Information --//
  "menu.about": "À propos de NCB",
  "menu.products": "Produits",
  "menu.pricing": "Tarifs",
  "menu.contact": "Contacter",
  "menu.privacy": "Politique de confidentialité",
  "menu.terms": "Termes et conditions",
  "menu.title": "Travail",
  "menu.support": "Aide",
  "menu.faq": "FAQ's",
  "menu.resources": "Outils",
  "menu.why": "Pourquoi bioHUB?",
  "menu.home": "Démarrer",
  "menu.biomaps": "bioMAPS",
  "menu.profile": "Profil",
  "menu.plans": "Des Plans",
  "menu.settings": "Les paramètres",
  "menu.open.project": "Projet Ouvert",
  "menu.new.project": "Nouveau Projet",
  "menu.new.monitoring": "Nouvelle Surveillance",
  "menu.flight.monitoring": "Surveiller le Vol",
  "menu.trap.monitoring": "Surveiller le Piège",
  "menu.biomapsInfo":
    "BioMAP visualise les zones où les contrôleurs biologiques sont libérés via le planificateur de mission.",
  "menu.licenses": "Licences",
  "menu.traps": "Pièges",

  //-- Generic Actions --//
  "action.login": "Entrer",
  "action.signUp": "Ouvrir un compte",
  "action.subscribe": "Abonner",
  "action.register": "Ouvrir un compte",
  "action.makeRegistration": "Faites votre pré-inscription ici!",
  "action.success": "Succès",
  "action.error": "Pardon!",
  "action.msgSuccess":
    "Votre pré-inscription a réussi dans notre base de données! \nBientôt, nous vous enverrons un message prochainement ✓",
  "action.msgError":
    "Une erreur s'est produite lors de votre pré-inscription! Veuillez réessayer plus tard.",
  "action.back": "Revenir",
  "action.getStarted": "Démarrer",
  "action.learnMore": "Savoir plus",
  "action.more": "Plus d'information...",
  "action.keep.connected": "Restez connecté",
  "action.login.message": "Entrez vos coordonnées pour vous connecter",
  "action.login.msgError":
    "Erreur de connexion. Veuillez vérifier vos données ou réessayer dans un instant.",
  "action.continue": "Continuer",
  "action.accept": "je suis d'accord",
  "action.indicator": "Inscrivez-vous en tant que: ",
  "action.signup.company": "Compagnie",
  "action.signup.single": "Gens",
  "action.accept.signup.terms":
    "J'accepte les conditions générales de la loi sur la protection des données LGPD.",
  "action.pay.now": "Payez Maintenant",
  "action.add.new": "Ajouter un nouveau",
  "action.edit": "Éditer",
  "action.edit.picture": "Changer la photo",
  "action.remove": "Ôter",
  "action.save": "Sauver",
  "action.cancel": "Annuler",
  "action.undo": "Défaire",
  "action.finish": "OK",
  "action.income": "Le revenu",
  "action.send": "Soumettre",
  "action.confirm": "Confirmer",
  "action.sendVerification": "Soumettre le code de vérification",
  "action.saveData": "Enregistrer des données",

  //-- Placeholders --//
  "placeholder.profile": "Profil",
  "placeholder.email": "Votre email",
  "placeholder.name": "Votre nom",
  "placeholder.phone": "Votre téléphone",
  "placeholder.company": "Votre entreprise",
  "placeholder.data.collect": "Comment êtes-vous arrivé à NCB?",
  "placeholder.password": "Mot de passe",
  "placeholder.search": "Rechercher",
  "placeholder.iva": "VAT",
  "placeholder.company.name": "Nom de la compagnie",
  "placeholder.zip": "Code Postal",
  "placeholder.address": "Route",
  "placeholder.number": "Nombre",
  "placeholder.complement": "Complément",
  "placeholder.country": "Parents",
  "placeholder.state.department": "Etat / Département",
  "placeholder.city": "Ville",
  "placeholder.comercial.phone": "Téléphone de travail",
  "placeholder.aviation.reg": "Immatriculation aéronautique",
  "placeholder.agriculture.reg": "Registre agricole",
  "placeholder.role": "Occupation",
  "placeholder.document": "Document",
  "placeholder.rg": "RG",
  "placeholder.crea": "CREA",
  "placeholder.ie": "I.E.",
  "placeholder.im": "I.M.",
  "placeholder.trade.name": "Nome Comercial",
  "placeholder.type": "Type d'utilisateur",
  "placeholder.contact": "Contact",
  "placeholder.description": "Bio",
  "placeholder.message": "Un message",
  "placeholder.project": "Projet",
  "placeholder.area": "Aire",
  "placeholder.aircraft": "Aéronef",

  "profile.account": "Mon Compte",
  "profile.profile": "Mon Profil",

  "profile.selectedUser": "choisi",

  //-- Options --//
  "option.preferences.generalinfo": "Informations générales",
  "option.preferences.unitsystem": "Système d'unité",
  "option.preferences.twofactorauth": "Authentification à deux facteurs",
  "option.preferences.sexagesimalcoordinates": "Utiliser les coordonnées sexagésimales",
  "option.preferences.separatelybatteries": "Afficher les piles séparément",
  "option.preferences.metric": "Métrique",
  "option.preferences.imperial": "Impérial",
  "option.search.engine": "Moteurs de recherche",
  "option.facebook": "Facebook",
  "option.linkedin": "Linkedin",
  "option.recommendation": "Recommandations",
  "option.email.marketing": "E-mail Marketing",
  "option.events": "Événement",
  "option.others": "Autres",
  "option.which": "Quels?",

  //-- Map --//
  "map.title": "Carte",
  "map.defaultRegion": "FR",
  "map.editMode.addPolygon":
    "Touchez sur le plan pour ajouter un point. Appuyez longuement sur un marqueur pour le déplacer.",
  "map.editMode.error.theePointsMinimum": "Indiquez au moins trois points.",
  "map.editMode.error.interesection":
    "L'aire tracée contient un ou plusieurs intersections. Merci de tracer un polygone simple.",
  "map.removeArea": "Suprimmer cette aire?",
  "map.newArea": "Nouvelle aire",
  "map.loadingList": "chargement...",
  "map.editPolygonPoints": "Modifier polygone",
  "map.editAreaProperties": "Modifier aire",
  "map.deleteArea": "Supprimmer aire",
  "map.importAreas.importAreasAction": "Importer des aires",
  "map.newArea.createAreaModalTitle": "Créer nouvelle aire",
  "map.newArea.importAreaModalTitle": "Importer aires",
  "map.newArea.areaName": "Nom de l'aire",
  "map.newProject.title": "Créer nouveau projet",
  "map.newProject.projectName": "Nouveau projet",
  "map.newProject.optionalImportKml": "Importer archiver",
  "map.newProject.kmlFileChoose": "Ouvrir...",
  "map.newProject.releaser": "Libérateur",
  "map.newProject.input": "Intrant",
  "map.newProject.releaseRate": "Taux de libération",
  "map.type.roadmap": "Carte",
  "map.type.satellite": "Satellite",
  "map.type.hybrid": "Hybride",
  "map.newProject.instantaneousReleaseRate": "Taux de libération instantanée",
  "map.areasAmountSing": "{amount} aire",
  "map.areasAmountPl": "{amount} aires",

  //-- Route planning --//
  "routePlanning.param.areaPadding": "Marge du trajet",
  "routePlanning.param.flightHeight": "Hauteur de vol",
  "routePlanning.param.flightSpeed": "Vitesse de vol",
  "routePlanning.param.trackWidth": "Largeur de bande",
  "routePlanning.param.considerRelief": "Tenir compte du relief du terrain",

  "routePlanning.param.waypoint.height": "Hauteur",
  "routePlanning.param.waypoint.curveRadius": "Rayon de courbe",
  "routePlanning.param.waypoint.speed": "La vitesse",
  "routePlanning.param.waypoint.orientation": "Orientation",

  "routePlanning.param.waypoint.actions": "Actions",
  "routePlanning.param.waypoint.action.enableEquipment": "Activer l'équipement",
  "routePlanning.param.waypoint.action.disableEquipment": "Désactiver l'équipement",
  "routePlanning.param.waypoint.action.none": "Rien",

  //-- LANGUAGES --//
  language: "Langue",
  "language.pt": "Portugais brésilien",
  "language.es": "Espanol",
  "language.en": "Anglais",
  "language.fr": "Français",
  "language.name": "Français",
  "language.region": "FR",
  "language.decimalSeparator": ",",

  //- Reports -//
  "report.issueDate": "Date d'émission:",
  "report.area": "Area",
  "report.plannedFlight": "Vol planifié",
  "report.details": "Des détails",
  "report.dateHour": "Date et l'heure:",
  "report.drone": "Drone:",
  "report.manufacturer": "Fabricant:",
  "report.anac": "ANAC:",
  "report.releasedProduct": "Produit sorti:",
  "report.releasing": "Libérateur",
  "report.bandWidth": "Largeur de bande",
  "report.plannedSpeed": "Vitesse planifiée",
  "report.releaseRate": "Taux de sortie",
  "report.realFlight": "Vol réel",
  "report.flightTime": "Temps de vol",
  "report.averageReleaseRate": "Taux de sortie moyen",
  "report.averageSpeed": "Vitesse moyenne",
  "report.totalReleased": "Total publié",
  "report.totalArea": "Superficie totale",
  "report.traps": "Les pièges",
  "report.serialNumber": "Número de série",
  "report.coveredHectares": "Hectares couverts",
  "report.dateMeasurament": "Date de la dernière mesure",
  "report.specie": "Espèce",
  "report.adultsNumber": "Nombre d'adultes",
  "report.noAvailable": "Aucun article disponible",
  "report.mapRecord": "Enregistrement MAPA:",
  "report.contractor": "Prestataire:",
  "report.property": "Propriété:",
  "report.localization": "Emplacement:",
  "report.county": "Comté:",
  "report.uf": "UF:",
  "report.doc": "CNPJ/CPF:",
  "report.serviceType": "Type de service:",
  "report.product": "Produit",
  "report.formulation": "Formulation",
  "report.dosage": "Dosage",
  "report.toxicClass": "Classe Toxique",
  "report.adjuvant": "Adjuvant",
  "report.culture": "Culture: ",
  "report.volume": "Volume (litres or kg / ha):",
  "report.others": "Autres: ",
  "report.agronomicalRecipe": "Recette agronomique n:",
  "report.issuedOn": "Publié le:",
  "report.basicParameter": "Paramètres de base de l'application",
  "report.maxTemperature": "Température max.=",
  "report.relativeUnitMin": "Unité relative min.=",
  "report.equipment": "Équipement=",
  "report.model": "Modèle=",
  "report.type": "Type=",
  "report.angle": "Angle=",
  "report.flightHeight": "Hauteur de vol=",
  "report.bandwidth": "Largeur de bande (m)=",
  "report.date": "Date",
  "report.signatureCREA": "Nom, signature et CREA du professionnel",
  "report.weatherConditions": "Conditions météorologiques dans l'application",
  "report.morning": "Matin",
  "report.evening": "Soir",
  "report.applicationStart": "Début de l'application: heures et fin: heures",
  "report.initial": "Initiale",
  "report.final": "Final",
  "report.temperature": "Température",
  "report.relativeHumidity": "Umid. Relatif",
  "report.aircraftPrefixe": "Préfixe - Avion:",
  "report.dgpsUse": "Utilisation du DGPS: oui ou non",
  "report.windSpeed": "La vitesse Vent",
  "report.trackCoordinates": "Suivi des coordonnées:",
  "report.observation": "Noter:",
  "report.signatureEng": "Nom, signature et CREA de l'ingénieur Agronomo:",
  "report.print": "Imprimer",

  // Pre-registration research
  "preregistration.interest.question":
    "Selon vous, qu'est-ce qui est le plus important dans le\ncontrôle biologique de précision?",
  "preregistration.interest.financial": "Financière",
  "preregistration.interest.financial.cost": "Cout d'opération",
  "preregistration.interest.financial.savings": "Économies d'intrants",
  "preregistration.interest.financial.pest": "Réduction des parasites",
  "preregistration.interest.financial.production": "Augmentation de la production",

  "preregistration.interest.sustainability": "Durabilité",
  "preregistration.interest.sustainability.carbonfootprint": "Réduction de l'empreinte carbone",
  "preregistration.interest.sustainability.pesticides": "Réduction des pesticides",
  "preregistration.interest.sustainability.environment": "Environnement",
  "preregistration.interest.sustainability.water": "Économie d'eau",

  "preregistration.interest.reliability": "Fiabilité",
  "preregistration.interest.reliability.precision": "Précision",
  "preregistration.interest.reliability.releasingrate": "Taux de libération",
  "preregistration.interest.reliability.tracking": "Suivi d'itinéraire",
  "preregistration.interest.reliability.coverage": "Zone de couverture",
};

export default messagesMap;
