import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";

import Teaser from "../pages/Teaser";
import { SystemState } from "../store/reducers/systemReducer";
import { LoginUserStatus } from "../store/reducers/loginReducer";

const Routes = () => (
  // <BrowserRouter>
  <Switch>
    <Route path="/readiness_check">
      <h3>App is ready</h3>
    </Route>
    <Route path="/liveness_check">
      <h3>App is live</h3>
    </Route>
    <Route path="/">
      <Teaser />
    </Route>

    {/* Website  */}
  </Switch>
);

export default Routes;

/**
 *  Private routes that will redirect to /login if accessed without authentication.
 * */
function PrivateRoute(props: { children: React.ReactNode } & RouteProps): JSX.Element {
  const loginState = useSelector((state: SystemState) => state.login);

  // `children` is a `React.ReactNode`, `...rest` is all the rest (`RouteProps` without `children`).
  // It's important that `...rest` does not have `children`, because then `render` would be ignored.
  const { children, ...rest } = props;

  // This is just a log, the real logic is up ahead.
  if (loginState.status === LoginUserStatus.loggedOut) {
    console.log("Not authenticated, will redirect to login.");
  }

  // https://reactrouter.com/web/example/auth-workflow
  return (
    <Route
      {...rest} // <- Pass all props that are passed to this component, *except* children.
      render={({ location }) =>
        // If the user is authenticated, proceed to render the children passed to this component.
        loginState.status !== LoginUserStatus.loggedOut ? (
          props.children
        ) : (
          // Otherwise, redirect to /login setting this page as the location's "from".
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location.pathname },
            }}
          />
        )
      }
    />
  );
}
