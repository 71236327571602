import { REHYDRATE } from "redux-persist/es/constants";
import { CHANGE_LOCALE, SET_LOCALE_FROM_PREFERENCES } from "../actions/localeActions";
import { SystemAction } from "../actions/systemActions";

export type BiohubLocale = "pt-BR" | "en" | "es" | "fr";

// Other things may come to exist in this type, but for now it really is just
// the locale.
export type LocaleState = {
  localeCode: BiohubLocale;
  changed?: boolean;
};

const INITIAL_STATE: LocaleState = {
  localeCode: getBrowserLanguage(),
};

/**
 * Function that gets the language the browser is installed in, and returns the
 * best match among the languages in BiohubLocale.
 *
 * Note that this doesn't read the languages that the user configured as their preferred
 * languages, but rather the browser installation's language. Hopefully that is sufficient.
 */
function getBrowserLanguage(): BiohubLocale {
  const browserLang = window.navigator.language.toLocaleLowerCase();
  // TODO: There's probably a better logic for choosing the closest locale.
  if (browserLang.includes("en")) {
    return "en";
  }
  if (browserLang.includes("pt")) {
    return "pt-BR";
  }
  if (browserLang.includes("es")) {
    return "es";
  }
  if (browserLang.includes("fr")) {
    return "fr";
  }

  return "en";
}

// Reducer
export function localeReducer(state = INITIAL_STATE, action: SystemAction): LocaleState {
  switch (action.type) {
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.locale,
          }
        : state;
    case SET_LOCALE_FROM_PREFERENCES:
      return {
        ...state,
        localeCode: getBrowserLanguage(),
      };
    case CHANGE_LOCALE:
      return {
        ...state,
        changed: true,
        localeCode: action.payload.locale,
      };
    default:
      return state;
  }
}
