import {
  BiohubError,
  clearBiohubAuthorizationToken,
  setBiohubAuthorizationToken,
} from "../../services/axios/BiohubApi";
import {
  logOut,
  LOG_IN_FAILURE,
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_OUT,
  RESET_ERROR,
} from "../actions/loginActions";
import { SystemAction } from "../actions/systemActions";

import { REHYDRATE } from "redux-persist";
import { store } from "..";
import { fetchCollections } from "../actions/collectionsActions";

export enum LoginUserStatus {
  /** User is logged out (neither token nor profile are available). */
  loggedOut,
  /** User is logged in (token is available), but needs to accept agreements. */
  loggedInPendingAgreements,
  /** User is logged in (token is available), but needs to verify e-mail address. */
  loggedInPendingEmailVerification,
  /** User is logged in and their profile is available in the store. */
  loggedIn,
}

/** Type of the state when the user is not yet logged in. */
export type LoginStateLoggedOut = {
  status: LoginUserStatus.loggedOut;
  token: null;
  //profile: null;
};

/** Type of the state when the user has some pending action that blocks app functionality. */
export type LoginStateLoggedInPendingAction = {
  status:
    | LoginUserStatus.loggedInPendingAgreements
    | LoginUserStatus.loggedInPendingEmailVerification;
  token: string;
  //profile: null;
};

/** Type of the state when the user is logged in without problems. */
export type LoginStateLoggedIn = {
  status: LoginUserStatus.loggedIn;
  token: string;
  //profile: BiohubModel.Profile;
};

/**
 * Fields in this type:
 *
 * - loading: A boolean that's set to true only when a login request is in progress.
 * - status: Determines which set of pages the user can access.
 * - token: The access token obtained by logging in. Note that this isn't strictly necessary because
 *      the service layer "remembers" the token on its own after a login. The token is duplicated
 *      here if necessary for some other purpose.
 * - profile: User profile data, available while logged in.
 * - loading: Flag that determines if the login is currently happening.
 * - error: Error that can be displayed to the user.
 *
 * `profile` and `token` will only be non-null when `status` is `loggedIn`. Please, always check
 * the `status` with a type guard in order to access `profile` and `token`.
 */
export type LoginState = { loading: boolean; error: BiohubError | null } & (
  | LoginStateLoggedOut
  | LoginStateLoggedInPendingAction
  | LoginStateLoggedIn
);

const INITIAL_STATE: LoginState = {
  loading: false,
  error: null,
  status: LoginUserStatus.loggedOut,
  token: null,
  //profile: null,
};

// Reducer
export function loginReducer(state = INITIAL_STATE, action: SystemAction): LoginState {
  switch (action.type) {
    // Redux-persist retrieve action
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.login,
          }
        : state;
    // Internal login process actions
    case LOG_IN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOG_IN_SUCCESS:
      // Note that the login action already sets the token. This is just an additional precautionary
      // call to ensure that subsequent calls will in fact use the token.
      if (action.payload.loginState.status === LoginUserStatus.loggedIn) {
        setBiohubAuthorizationToken(action.payload.loginState.token);
      }
      return action.payload.loginState;
    case LOG_IN_FAILURE:
      // Precautionary call: We don't expect the token to be set at this point.
      clearBiohubAuthorizationToken();
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case LOG_OUT:
      // Precautionary call
      clearBiohubAuthorizationToken();
      // Return the initial state to clear all user info, including the token.
      return INITIAL_STATE;
    default:
      return state;
  }
}
