import {
  Company,
  DirectClient,
  IndirectClient,
  Profile,
  ProfilePreferences,
  Role,
} from "biohub-model";
import {
  BiohubError,
  clearBiohubAuthorizationToken,
  setBiohubAuthorizationToken,
} from "../../services/axios/BiohubApi";
import { SystemThunk } from "../systemThunk";

import ProfileService from "../../services/ProfileService";
import CompanyService from "../../services/CompanyService";
import { LOG_IN_FAILURE } from "./loginActions";
import { BiohubLocale } from "../reducers/localeReducer";
import { changeLocale } from "./localeActions";
import { useSelector } from "react-redux";
import { SystemState } from "../reducers/systemReducer";
import { SystemAction } from "./systemActions";
import { Store } from "redux";

export const PROFILE_READING = "PROFILE_READING";
export const PROFILE_READ_SUCCESS = "PROFILE_READ_SUCCESS";

export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_REQUEST_ERROR = "PROFILE_UPDATE_REQUEST_ERROR";
export const PROFILE_UPDATE_REQUEST_SUCCESS = "PROFILE_UPDATE_REQUEST_SUCCESS";

export const PROFILE_RELOAD = "PROFILE_RELOAD";
export const PROFILE_RELOAD_ERROR = "PROFILE_RELOAD_ERROR";
export const PROFILE_RELOAD_SUCCESS = "PROFILE_RELOAD_SUCCESS";

export const PROFILE_ADITION = "PROFILE_ADITION";
export const PROFILE_ADITION_SUCCESS = "PROFILE_ADITION_SUCCESS";
export const PROFILE_ADITION_ERROR = "PROFILE_ADITION_ERROR";

export const PROFILE_UPDATE_COMPANY = "PROFILE_UPDATE_COMPANY";
export const PROFILE_UPDATE_COMPANY_SUCCESS = "PROFILE_UPDATE_COMPANY_SUCCESS";
export const PROFILE_UPDATE_COMPANY_ERROR = "PROFILE_UPDATE_COMPANY_ERROR";

export const PROFILE_RELOAD_DIRECT_CLIENT = "PROFILE_RELOAD_DIRECT_CLIENT";
export const PROFILE_RELOAD_DIRECT_CLIENT_ERROR = "PROFILE_RELOAD_DIRECT_CLIENT_ERROR";
export const PROFILE_RELOAD_DIRECT_CLIENT_SUCCESS = "PROFILE_RELOAD_DIRECT_CLIENT_SUCCESS";

export const READ_INDIRECT_CLIENTS = "READ_INDIRECT_CLIENTS";
export const READ_INDIRECT_CLIENTS_ERROR = "READ_INDIRECT_CLIENTS_ERROR";
export const READ_INDIRECT_CLIENTS_SUCCESS = "READ_INDIRECT_CLIENTS_SUCCESS";

export const ADITION_INDIRECT_CLIENT = "ADITION_INDIRECT_CLIENT";
export const ADITION_INDIRECT_CLIENT_ERROR = "ADITION_INDIRECT_CLIENT_ERROR";
export const ADITION_INDIRECT_CLIENT_SUCCESS = "ADITION_INDIRECT_cLIENT_SUCCESS";

export const UPDATE_INDIRECT_CLIENT = "UPDATE_INDIRECT_CLIENT";
export const UPDATE_INDIRECT_CLIENT_ERROR = "UPDATE_INDIRECT_CLIENT_ERROR";
export const UPDATE_INDIRECT_CLIENT_SUCCESS = "UPDATE_INDIRECT_CLIENT_SUCCESS";

export const UPDATE_PROFILE_PREFERENCES = "UPDATE_PROFILE_PREFERENCES";
export const UPDATE_PROFILE_PREFERENCES_ERROR = "UPDATE_PROFILE_PREFERENCES_ERROR";
export const UPDATE_PROFILE_PREFERENCES_SUCCESS = "UPDATE_PROFILE_PREFERENCES_SUCCESS";

export const PROFILE_REMOTION = "PROFILE_REMOTION";
export const PROFILE_REMOTION_ERROR = "PROFILE_REMOTION_ERROR";
export const PROFILE_REMOTION_SUCCESS = "PROFILE_REMOTION_SUCCESS";

export const INDIRECT_CLIENT_REMOTION = "INDIRECT_CLIENT_REMOTION";
export const INDIRECT_CLIENT_REMOTION_ERROR = "INDIRECT_CLIENT_REMOTION_ERROR";
export const INDIRECT_CLIENT_REMOTION_SUCCESS = "INDIRECT_CLIENT_REMOTION_SUCCESS";

export type ProfileAction =
  /** Action fired after the login.
   * Should set a loading flag in the reducer.
   * If the action gets an error the user will be log out.
   * */
  | {
      type: typeof PROFILE_READING;
    }
  /** Action fired at the end of the profile read. */
  | {
      type: typeof PROFILE_READ_SUCCESS;
      payload: {
        profile: Profile;
        directClient: DirectClient | null;
      };
    }
  /** Action fired in a save profile edition.
   * Should only set a loading flag in the reducer.
   * */
  | {
      type: typeof PROFILE_UPDATE_REQUEST;
      payload: {
        updatingProfileId: string;
      };
    }
  /** Action fired after an error to save the edited profile.
   */
  | {
      type: typeof PROFILE_UPDATE_REQUEST_ERROR;
      payload: {
        profileId: string;
        error: BiohubError;
      };
    }
  /** Action fired after a successful profile update.
   */
  | {
      type: typeof PROFILE_UPDATE_REQUEST_SUCCESS;
      payload: {
        profile: { id: string } & Partial<Profile>;
      };
    }
  /** Action fired to reload a profile.
   * Should set a loading flag in the reducer.
   */
  | {
      type: typeof PROFILE_RELOAD;
      payload: {
        profileId: string;
      };
    }
  /** Action fired after an error reloading a profile.
   */
  | {
      type: typeof PROFILE_RELOAD_ERROR;
      payload: {
        profileId: string;
        error: BiohubError;
      };
    }
  /** Action fired after a successful profile reload
   * Should set a loading flag in the reducer.
   */
  | {
      type: typeof PROFILE_RELOAD_SUCCESS;
      payload: {
        profile: Profile;
      };
    }
  /** Action fired to add a profile.
   * Should set a loading flag in the reducer.
   */
  | {
      type: typeof PROFILE_ADITION;
    }
  /** Action fired after an error updating a profile.
   */
  | {
      type: typeof PROFILE_ADITION_ERROR;
      payload: {
        profileId: string;
        error: BiohubError;
      };
    }
  /** Action fired after a successful profile update.
   */
  | {
      type: typeof PROFILE_ADITION_SUCCESS;
      payload: {
        profile: Profile;
      };
    }
  /** Action fired after a company update.
   * Should set a loading flag in the reducer.
   */
  | {
      type: typeof PROFILE_UPDATE_COMPANY;
      payload: {
        company: { id: string } & Partial<Company>;
      };
    }
  /** Action fired after an error updating a company.
   */
  | {
      type: typeof PROFILE_UPDATE_COMPANY_ERROR;
      payload: {
        companyId: string;
        error: BiohubError;
      };
    }
  /** Action fired after a successful company update.
   */
  | {
      type: typeof PROFILE_UPDATE_COMPANY_SUCCESS;
      payload: {
        company: { id: string } & Partial<Company>;
      };
    }
  /** Action fired after a direct client reload.
   * Should set a loading flag in the reducer.
   */
  | {
      type: typeof PROFILE_RELOAD_DIRECT_CLIENT;
      payload: {
        directClientId: string;
      };
    }
  /** Action fired after an error reloading the direc client.
   */
  | {
      type: typeof PROFILE_RELOAD_DIRECT_CLIENT_ERROR;
      payload: {
        error: BiohubError;
        directClientId: string;
      };
    }
  /** Action fired after a successful direct client reload.
   */
  | {
      type: typeof PROFILE_RELOAD_DIRECT_CLIENT_SUCCESS;
      payload: {
        directClient: DirectClient;
      };
    }
  | {
      type: typeof ADITION_INDIRECT_CLIENT;
      payload: {
        indirectClient: IndirectClient;
      };
    }
  | {
      type: typeof ADITION_INDIRECT_CLIENT_ERROR;
      payload: {
        error: BiohubError;
        indirectClientId: string;
      };
    }
  | {
      type: typeof ADITION_INDIRECT_CLIENT_SUCCESS;
      payload: {
        indirectClient: IndirectClient;
      };
    }
  | {
      type: typeof UPDATE_INDIRECT_CLIENT;
      payload: {
        indirectClient: { id: string } & Partial<IndirectClient>;
      };
    }
  | {
      type: typeof UPDATE_INDIRECT_CLIENT_ERROR;
      payload: {
        error: BiohubError;
        indirectClientId: string;
      };
    }
  | {
      type: typeof UPDATE_INDIRECT_CLIENT_SUCCESS;
      payload: {
        indirectClient: { id: string } & Partial<IndirectClient>;
      };
    }
  | {
      type: typeof READ_INDIRECT_CLIENTS;
    }
  | {
      type: typeof READ_INDIRECT_CLIENTS_ERROR;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof READ_INDIRECT_CLIENTS_SUCCESS;
      payload: {
        indirectClients: Array<IndirectClient>;
      };
    }
  | {
      type: typeof UPDATE_PROFILE_PREFERENCES;
      payload: {
        profilePrefences: { id: string } & Partial<ProfilePreferences>;
      };
    }
  | {
      type: typeof UPDATE_PROFILE_PREFERENCES_ERROR;
      payload: {
        error: BiohubError;
        profileId: string;
      };
    }
  | {
      type: typeof UPDATE_PROFILE_PREFERENCES_SUCCESS;
      payload: {
        profilePrefences: { id: string } & Partial<ProfilePreferences>;
      };
    }
  | {
      type: typeof PROFILE_REMOTION;
      payload: {
        profileId: string;
      };
    }
  | {
      type: typeof PROFILE_REMOTION_ERROR;
      payload: {
        profileId: string;
        error: BiohubError;
      };
    }
  | {
      type: typeof PROFILE_REMOTION_SUCCESS;
      payload: {
        profileId: string;
      };
    }
  | {
      type: typeof INDIRECT_CLIENT_REMOTION;
      payload: {
        indirectClientId: string;
      };
    }
  | {
      type: typeof INDIRECT_CLIENT_REMOTION_ERROR;
      payload: {
        indirectClientId: string;
        error: BiohubError;
      };
    }
  | {
      type: typeof INDIRECT_CLIENT_REMOTION_SUCCESS;
      payload: {
        indirectClientId: string;
      };
    };

export function loadProfileInformation(
  userId: string,
  locale: BiohubLocale,
  localeChanged?: boolean
): SystemThunk {
  return async (dispatch) => {
    //This will set a loading state.
    dispatch({
      type: PROFILE_READING,
    });

    const profileResult = await ProfileService.getProfile(userId);
    if (!profileResult.success) {
      dispatch({
        type: LOG_IN_FAILURE,
        payload: {
          error: profileResult.error,
        },
      });
      return;
    }

    const profile: Profile = profileResult.data as Profile;

    let directClient: DirectClient | null = null;

    if (profile.directClientId !== null) {
      const directClientResult = await ProfileService.readDirectClient(profile.directClientId);
      /*if (!directClientResult.success) {
        dispatch({
          type: LOG_IN_FAILURE,
          payload: {
            error: directClientResult.error,
          },
        });
        return;
      }*/
      if (directClientResult.success) {
        directClient = directClientResult.data as DirectClient;

        dispatch({
          type: READ_INDIRECT_CLIENTS,
        });

        const readIndirectClients = await ProfileService.readIndirectClients(directClient.id);
        if (!readIndirectClients.success) {
          dispatch({
            type: READ_INDIRECT_CLIENTS_ERROR,
            payload: {
              error: readIndirectClients.error,
            },
          });
        } else {
          dispatch({
            type: READ_INDIRECT_CLIENTS_SUCCESS,
            payload: {
              indirectClients: readIndirectClients.data as Array<IndirectClient>,
            },
          });
        }
      }
    }

    dispatch({
      type: PROFILE_READ_SUCCESS,
      payload: {
        profile: profile,
        directClient: directClient,
      },
    });

    if (
      localeChanged !== undefined &&
      localeChanged &&
      profile.role !== Role.external &&
      locale !== profile.preferences.languageCode
    ) {
      dispatch(updateProfilePreferences({ id: profile.id, languageCode: locale }));
      profile.preferences.languageCode = locale;
    }
  };
}

export function updateProfile(profile: { id: string } & Partial<Profile>): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: PROFILE_UPDATE_REQUEST,
      payload: {
        updatingProfileId: profile.id,
      },
    });

    const updateResult = await ProfileService.updateProfile(profile);
    if (!updateResult.success) {
      dispatch({
        type: PROFILE_UPDATE_REQUEST_ERROR,
        payload: {
          profileId: profile.id,
          error: updateResult.error,
        },
      });
      return;
    }

    dispatch({
      type: PROFILE_UPDATE_REQUEST_SUCCESS,
      payload: {
        profile: profile,
      },
    });
  };
}

export function reloadProfile(profileId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: PROFILE_RELOAD,
      payload: {
        profileId: profileId,
      },
    });

    const reloadProfile = await ProfileService.getProfile(profileId);
    if (!reloadProfile.success) {
      dispatch({
        type: PROFILE_RELOAD_ERROR,
        payload: {
          profileId: profileId,
          error: reloadProfile.error,
        },
      });
      return;
    }

    const profile: Profile = reloadProfile.data as Profile;
    dispatch({
      type: PROFILE_RELOAD_SUCCESS,
      payload: {
        profile: profile,
      },
    });
  };
}

export function readIndirectClients(directClientId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: READ_INDIRECT_CLIENTS,
    });

    const result = await ProfileService.readIndirectClients(directClientId);
    if (!result.success) {
      dispatch({
        type: READ_INDIRECT_CLIENTS_ERROR,
        payload: {
          error: result.error,
        },
      });
      return;
    }

    dispatch({
      type: READ_INDIRECT_CLIENTS_SUCCESS,
      payload: {
        indirectClients: result.data as Array<IndirectClient>,
      },
    });
  };
}

export function addProfile(profile: Profile): SystemThunk {
  return async (dispatch) => {
    dispatch({ type: PROFILE_ADITION });

    const addResult = await ProfileService.createProfile(profile);
    if (!addResult.success) {
      dispatch({
        type: PROFILE_ADITION_ERROR,
        payload: {
          profileId: profile.id,
          error: addResult.error,
        },
      });
      return;
    }

    dispatch({
      type: PROFILE_ADITION_SUCCESS,
      payload: {
        profile: profile,
      },
    });
  };
}

export function updateCompany(company: { id: string } & Partial<Company>): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: PROFILE_UPDATE_COMPANY,
      payload: {
        company: company,
      },
    });

    const updateResult = await CompanyService.updateCompany(company);
    if (!updateResult.success) {
      dispatch({
        type: PROFILE_UPDATE_COMPANY_ERROR,
        payload: {
          companyId: company.id,
          error: updateResult.error,
        },
      });
      return;
    }

    dispatch({
      type: PROFILE_UPDATE_COMPANY_SUCCESS,
      payload: {
        company: company,
      },
    });
  };
}

export function reloadDirectClient(directClientId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: PROFILE_RELOAD_DIRECT_CLIENT,
      payload: {
        directClientId: directClientId,
      },
    });

    const reloadResult = await ProfileService.readDirectClient(directClientId);
    if (!reloadResult.success) {
      dispatch({
        type: PROFILE_RELOAD_DIRECT_CLIENT_ERROR,
        payload: {
          error: reloadResult.error,
          directClientId: directClientId,
        },
      });
      return;
    }

    dispatch({
      type: PROFILE_RELOAD_DIRECT_CLIENT_SUCCESS,
      payload: {
        directClient: reloadResult.data,
      },
    });
  };
}

export function addIndirectClient(indirectClient: IndirectClient): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: ADITION_INDIRECT_CLIENT,
      payload: {
        indirectClient: indirectClient,
      },
    });

    const result = await ProfileService.addIndirectClient(indirectClient);
    if (!result.success) {
      dispatch({
        type: ADITION_INDIRECT_CLIENT_ERROR,
        payload: {
          error: result.error,
          indirectClientId: indirectClient.id,
        },
      });
      return;
    }

    dispatch({
      type: ADITION_INDIRECT_CLIENT_SUCCESS,
      payload: {
        indirectClient: indirectClient,
      },
    });
  };
}

export function updateIndirectClient(
  indirectClient: { id: string } & Partial<IndirectClient>
): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_INDIRECT_CLIENT,
      payload: {
        indirectClient: indirectClient,
      },
    });

    const result = await ProfileService.updateIndirectClient(indirectClient);
    if (!result.success) {
      dispatch({
        type: UPDATE_INDIRECT_CLIENT_ERROR,
        payload: {
          error: result.error,
          indirectClientId: indirectClient.id,
        },
      });
      return;
    }

    dispatch({
      type: UPDATE_INDIRECT_CLIENT_SUCCESS,
      payload: {
        indirectClient: indirectClient,
      },
    });
  };
}

export function updateProfilePreferences(
  profilePrefences: { id: string } & Partial<ProfilePreferences>
): SystemThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_PROFILE_PREFERENCES,
      payload: {
        profilePrefences: profilePrefences,
      },
    });

    const result = await ProfileService.updateProfilePreferences(profilePrefences);
    if (!result.success) {
      dispatch({
        type: UPDATE_PROFILE_PREFERENCES_ERROR,
        payload: {
          error: result.error,
          profileId: profilePrefences.id,
        },
      });
      return;
    }

    const locale: BiohubLocale = getState().locale.localeCode;

    if (
      locale !== undefined &&
      profilePrefences.languageCode !== undefined &&
      profilePrefences.languageCode !== locale
    ) {
      dispatch(changeLocale(profilePrefences.languageCode as BiohubLocale));
    }

    dispatch({
      type: UPDATE_PROFILE_PREFERENCES_SUCCESS,
      payload: {
        profilePrefences: profilePrefences,
      },
    });
  };
}

export function removeProfile(userProfile: Profile, profileId: string): SystemThunk {
  return async (dispatch) => {
    if (userProfile.id !== profileId) {
      dispatch({
        type: PROFILE_REMOTION,
        payload: {
          profileId: profileId,
        },
      });

      const result = await ProfileService.removeProfile(profileId);
      if (!result.success) {
        dispatch({
          type: PROFILE_REMOTION_ERROR,
          payload: {
            profileId: profileId,
            error: result.error,
          },
        });
        return;
      }

      dispatch({
        type: PROFILE_REMOTION_SUCCESS,
        payload: {
          profileId: profileId,
        },
      });
    }
  };
}

export function removeIndirectClient(indirectClientId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: INDIRECT_CLIENT_REMOTION,
      payload: {
        indirectClientId: indirectClientId,
      },
    });

    const result = await ProfileService.removeIndirectClient(indirectClientId);
    if (!result.success) {
      dispatch({
        type: INDIRECT_CLIENT_REMOTION_ERROR,
        payload: {
          error: result.error,
          indirectClientId: indirectClientId,
        },
      });
      return;
    }

    dispatch({
      type: INDIRECT_CLIENT_REMOTION_SUCCESS,
      payload: {
        indirectClientId: indirectClientId,
      },
    });
  };
}

export async function persistReloadProfile(
  store: Store<any, SystemAction> & { dispatch: unknown },
  profile: Profile,
  token: string
): Promise<void> {
  store.dispatch({
    type: PROFILE_READING,
  });

  //setBiohubAuthorizationToken(token);

  const profileResult = await ProfileService.getProfile(profile.id);
  if (!profileResult.success) {
    clearBiohubAuthorizationToken();
    store.dispatch({
      type: LOG_IN_FAILURE,
      payload: {
        error: profileResult.error,
      },
    });
    return;
  }

  profile = profileResult.data;

  let directClient: DirectClient | null = null;

  if (profile.directClientId !== null) {
    const directClientResult = await ProfileService.readDirectClient(profile.directClientId);
    if (directClientResult.success) {
      directClient = directClientResult.data as DirectClient;

      store.dispatch({
        type: READ_INDIRECT_CLIENTS,
      });

      const readIndirectClients = await ProfileService.readIndirectClients(directClient.id);
      if (!readIndirectClients.success) {
        store.dispatch({
          type: READ_INDIRECT_CLIENTS_ERROR,
          payload: {
            error: readIndirectClients.error,
          },
        });
      } else {
        store.dispatch({
          type: READ_INDIRECT_CLIENTS_SUCCESS,
          payload: {
            indirectClients: readIndirectClients.data as Array<IndirectClient>,
          },
        });
      }
    }
  }

  store.dispatch({
    type: PROFILE_READ_SUCCESS,
    payload: {
      profile: profile,
      directClient: directClient,
    },
  });
}
