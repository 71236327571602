import { Area, Project } from "biohub-model";
import { BiohubError } from "../../services/axios/BiohubApi";
import {
  APPEND_TO_ALL_AREAS_LIST,
  CLEAR_ELEVATION_DATA,
  CLOSE_PROJECT,
  REQUEST_PLANNED_ROUTE,
  SET_ELEVATION_DATA,
  SET_PLANNED_ROUTE,
  SET_PROJECT_LIST_FAILURE,
  SET_PROJECT_LIST_REQUEST,
  SET_PROJECT_LIST_SUCCESS,
  SET_SELECTED_AREA,
  SET_SELECTED_PROJECT,
  UPDATE_AREAS_LIST,
  UPDATE_SELECTED_PROJECT,
} from "../actions/projectActions";
import { SystemAction } from "../actions/systemActions";

/**
 * This represents the state of the project that's currently open on the map.
 * Lists of projects should be stored somewhere else, in a page's state.
 * This part of the state also does not handle operations like creating projects, or saving the
 * current project. Other components should do that on their own, then submit changes to the state.
 */
export type ProjectState = {
  isLoadingProjectList: boolean;
  projectsError: BiohubError | null;
  projectList: Project[];
  /**
   * All areas for all projects.
   * This whole thing is unoptimized. Originally, we only kept the list of areas for the selected
   * project, since only one project is open at a time. However, now we also need to keep the list
   * of all projects for counting the amount of areas in each project. This also lets us preload all
   * lists of areas, but requires multiple requests. This can also be used as a cache for areas,
   * as long as it's kept updated
   */
  allAreaLists: {
    [projectId: string]: Area[];
  };
} & (
  | {
      isProjectLoaded: false;
    }
  | {
      isProjectLoaded: true;
      // I know this is repeated information, but it's party for convenience and partly
      // because the project list field was added later.
      project: Project;
      /**
       * Areas for the selected project.
       */
      areas: Area[];
      selectedAreaId: string | null;
      // Id of all areas whose route is being currently planned.
      isPlanningRoute: string[];
      /**
       * Elevation data for the selected area's planned path.
       * This will be missing if there's no selected area, or if the selected
       * area doesn't have a planned path, or if the request for elevation
       * data hasn't finished yet. This will always be in meters above sea level,
       * and in the same order as the waypoints.
       * This is expected to be set numerous times, but the elevation service
       * has an internal cache that minimizes the amount of required requests.
       */
      selectedAreaElevations: number[] | undefined;
      // add traps here too
    }
);

const INITIAL_STATE: ProjectState = {
  isLoadingProjectList: false,
  projectsError: null,
  projectList: [],
  isProjectLoaded: false,
  allAreaLists: {},
};

export function projectReducer(state = INITIAL_STATE, action: SystemAction): ProjectState {
  switch (action.type) {
    case SET_SELECTED_PROJECT:
      return {
        ...state,
        isProjectLoaded: true,
        isLoadingProjectList: false,
        project: action.payload.project,
        areas: action.payload.projectAreas,
        // Automatically select the first area id there is one.
        selectedAreaId:
          action.payload.projectAreas.length > 0 ? action.payload.projectAreas[0].id : null,
        isPlanningRoute: [],
        selectedAreaElevations: undefined,
        // Also update the object with all area lists with the areas array.
        allAreaLists: {
          ...state.allAreaLists,
          [action.payload.project.id]: action.payload.projectAreas,
        },
      };
    case UPDATE_SELECTED_PROJECT:
      if (!state.isProjectLoaded) {
        return state;
      } else {
        return {
          ...state,
          project: {
            ...state.project,
            ...action.payload.newProjectDiff,
          } as Project, // Ignore some typing problems
        };
      }
    case UPDATE_AREAS_LIST:
      // Remember to always update the object with all area lists.
      if (!state.isProjectLoaded) {
        return {
          ...state,
          allAreaLists: {
            ...state.allAreaLists,
            [action.payload.projectId]: action.payload.projectAreas,
          },
        };
      } else if (state.project.id === action.payload.projectId) {
        // Update the state only if the project that changed is the same as the project that's
        // currently selected. In practice, this should always be true.
        return {
          ...state,
          areas: action.payload.projectAreas,
          selectedAreaId: action.payload.selectedAreaId,
          allAreaLists: {
            ...state.allAreaLists,
            [action.payload.projectId]: action.payload.projectAreas,
          },
        };
      } else {
        return {
          ...state,
          allAreaLists: {
            ...state.allAreaLists,
            [action.payload.projectId]: action.payload.projectAreas,
          },
        };
      }
    case SET_SELECTED_AREA:
      // Type inference gets super weird for doing destructuring statements.
      if (!state.isProjectLoaded) {
        return state;
      } else {
        return {
          ...state,
          selectedAreaId: action.payload.areaId,
        };
      }
    case CLOSE_PROJECT:
      return {
        ...state,
        isProjectLoaded: false,
      };
    case REQUEST_PLANNED_ROUTE:
      if (!state.isProjectLoaded) {
        return state;
      } else {
        // Get the area
        const area = state.areas.find((a) => a.id === action.payload.areaId);
        if (!area) {
          return state;
        }

        const areasPlanningRoute = state.isPlanningRoute.concat([area.id]);
        // Set the home point as well, if one was informed.
        if (action.payload.newHomePoint) {
          const updatedArea: Area = {
            ...area,
            homePoint: action.payload.newHomePoint!,
          };
          const areaIndex = state.areas.findIndex((a) => a.id === action.payload.areaId);
          const areasClone = Array.from(state.areas);
          if (areaIndex !== -1) {
            areasClone[areaIndex] = updatedArea;
          }
          return {
            ...state,
            // Remove duplicates
            isPlanningRoute: Array.from(new Set(areasPlanningRoute)),
            // Add the area list that has the home point in it.
            areas: areasClone,
          };
        } else {
          return {
            ...state,
            // Remove duplicates
            isPlanningRoute: Array.from(new Set(areasPlanningRoute)),
          };
        }
      }
    case SET_PLANNED_ROUTE:
      if (!state.isProjectLoaded) {
        return state;
      } else {
        // Get the area
        const areaIndex = state.areas.findIndex((a) => a.id === action.payload.areaId);
        if (areaIndex === -1) {
          return state;
        }
        const area = state.areas[areaIndex];
        // Add the planned route to it, then save.
        const newArea: Area = {
          ...area,
          plannedRoute: action.payload.plannedRoute,
          // Use the new home point, if provided.
          homePoint: area.homePoint ?? action.payload.newHomePoint,
        };

        const areasCopy = Array.from(state.areas);
        areasCopy[areaIndex] = newArea;

        return {
          ...state,
          areas: areasCopy,
        };
      }
    case SET_PROJECT_LIST_REQUEST:
      return {
        ...state,
        isLoadingProjectList: true,
      };
    case SET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        isLoadingProjectList: false,
        projectList: action.payload.projects,
      };
    case SET_PROJECT_LIST_FAILURE:
      return {
        ...state,
        isLoadingProjectList: false,
        projectsError: action.payload.error,
      };
    case CLEAR_ELEVATION_DATA:
      if (!state.isProjectLoaded) {
        return state;
      } else {
        return {
          ...state,
          selectedAreaElevations: undefined,
        };
      }
    case SET_ELEVATION_DATA:
      // When setting elevation data, we have to check first if the area
      // that the data refers to is still the selected area.
      if (!state.isProjectLoaded) {
        return state;
      } else if (action.payload.areaId === state.selectedAreaId) {
        return {
          ...state,
          selectedAreaElevations: action.payload.elevationData,
        };
      } else {
        return state;
      }
    case APPEND_TO_ALL_AREAS_LIST:
      return {
        ...state,
        allAreaLists: {
          ...state.allAreaLists,
          [action.payload.projectId]: action.payload.areaList,
        },
      };
    default:
      return state;
  }
}
