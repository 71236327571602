import React from "react";
import { Text } from "./styles";

interface Props {
  text?: string;
  color?: "light" | "primary" | "secondary" | "highlighted" | "dark";
  size?: "small" | "medium" | "large" | "x-large";
  textAlign?: "center";
  appearance?:
    | "title"
    | "section-title"
    | "info-title"
    | "description"
    | "description-block"
    | "info-subtitle"
    | "carousel-title"
    | "carousel-subtitle"
    | "description-banner"
    | "menu-title"
    | "price"
    | "info-name"
    | "info-value"
    | "info-description"
    | "block-title"
    | "small-title"
    | "small-description"
    | "details-description";
  transform?: "lowercase" | "uppercase" | "capitalize";
}

export default (props: Props) => {
  return (
    <>
      <Text
        color={props.color}
        appearance={props.appearance}
        transform={props.transform}
        size={props.size}
        textAlign={props.textAlign}
      >
        {props.text}
      </Text>
    </>
  );
};
