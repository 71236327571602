import styled from "styled-components";
type Props = {
  className?: string;
  appearance?:
    | "img-logo"
    | "img-icon"
    | "img-divulgation"
    | "img-divulgation-banner"
    | "img-rounded"
    | "img-partner"
    | "img-product-card"
    | "img-client-card"
    | "img-avatar"
    | "tag"
    | "dashboard-icon"
    | "project-icon";
};

export const Image = styled.img.attrs((props: Props) => ({
  appearance: props.appearance,
}))`
  ${(props) =>
    props.appearance === "img-logo" &&
    `
    width: 60px;
    margin: 1rem;
    &:hover, &:focus {
      opacity: 0.75;
    }
  `}

  ${(props) =>
    props.appearance === "img-icon" &&
    `
    width: 20px;
  `}

  ${(props) =>
    props.appearance === "img-divulgation" &&
    `
    width: 220px;
    margin-left: -15px;
    @media screen and (max-width: 768px) {
      width: 110px;
      margin-top: 2rem;
    }
  `}

  ${(props) =>
    props.appearance === "img-divulgation-banner" &&
    `
    width: 320px;
    float: right;

    @media screen and (max-width: 680px) {
      width: 167px;
      float: left;
      margin: 0;
    }
  `}

  ${(props) =>
    props.appearance === "img-rounded" &&
    `
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 1200px) {
      width: 120px;
      height: 120px;
    }
    
  `}

  ${(props) =>
    props.appearance === "img-partner" &&
    `
    object-fit: cover;
    width: 240px;
    height: auto;

    @media screen and (max-width: 1200px) {
      width: 180px;
    }

    @media screen and (max-width: 1200px) {
      width: 160px;
    }

    @media screen and (max-width: 768px) {
      width: 180px;
      margin: auto;
    }

    
    @media screen and (max-width: 680px) {
      width: 140px;
    }
    
  `}

  ${(props) =>
    props.appearance === "img-product-card" &&
    `
    height: 240px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 10px;

    @media screen and (max-width: 991px) {
      height: 180px;
    }

    @media screen and (max-width: 480px) {
      height: 180px;
    }
 
  `}

  ${(props) =>
    props.appearance === "img-client-card" &&
    `
    height: 140px;
    width: 140px;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: 1;
    box-shadow: 0 0 2px var(--box-shadow);

    @media screen and (max-width: 991px) {
      position: absolute;
      left: 20%;
    }

    @media screen and (max-width: 680px) {
      left: calc(50% - 70px);
    }
    
  `}

  ${(props) =>
    props.appearance === "img-avatar" &&
    `
    height: 60px;
    width: 60px;
    object-fit: cover;
    border: 2px solid var(--dashboard-light-border);
    border-radius: 50%;

    @media screen and (max-width: 991px) {
      height: 50px;
      width: 50px;
    }

    @media screen and (max-width: 768px) {
      height: 40px;
      width: 40px;
    }
  `}

  ${(props) =>
    props.appearance === "tag" &&
    `
      width: 60px;
      display: block;
      margin-top: 2rem;
  `}

  ${(props) =>
    props.appearance === "project-icon" &&
    `
    width: 14px;
    margin-left: 2px;
    margin-right: 7px;
    filter: drop-shadow(0 0 0 black);
  `}
`;
