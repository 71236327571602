import { SystemThunk } from "../systemThunk";
import { BiohubError } from "../../services/axios/BiohubApi";
import DashboardService from "../../services/DashboardService";

// TODO: Put that types in the BiohubModel

type DashboardGraphValue = {
  index: number;
  value: number;
};

type DashboardGraphResponse = {
  scale: number;
  general: Array<DashboardGraphValue>;
  profiles: {
    [profileName: string]: Array<DashboardGraphValue>;
  };
  indirectClients: {
    [indirectClientName: string]: Array<DashboardGraphValue>;
  };
  drones: {
    [droneSerialNumber: string]: Array<DashboardGraphValue>;
  };
};

export const DASHBOARD_LOAD_HECTARES = "DASHBOARD_LOAD_HECTARES";
export const DASHBOARD_LOAD_HECTARES_ERROR = "DASHBOARD_LOAD_HECTARES_ERROR";
export const DASHBOARD_LOAD_HECTARES_SUCCESS = "DASHBOARD_LOAD_HECTARES_SUCCESS";

export const DASHBOARD_LOAD_INCOME = "DASHBOARD_LOAD_INCOME";
export const DASHBOARD_LOAD_INCOME_ERROR = "DASHBOARD_LOAD_INCOME_ERROR";
export const DASHBOARD_LOAD_INCOME_SUCCESS = "DASHBOARD_LOAD_INCOME_SUCCESS";

export type DashboardActions =
  | {
      type: typeof DASHBOARD_LOAD_HECTARES;
    }
  | {
      type: typeof DASHBOARD_LOAD_HECTARES_ERROR;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof DASHBOARD_LOAD_HECTARES_SUCCESS;
      payload: {
        data: DashboardGraphResponse;
        initialDate: Date;
        finalDate: Date;
        scale?: number;
      };
    }
  | {
      type: typeof DASHBOARD_LOAD_INCOME;
    }
  | {
      type: typeof DASHBOARD_LOAD_INCOME_ERROR;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof DASHBOARD_LOAD_INCOME_SUCCESS;
      payload: {
        data: DashboardGraphResponse;
        initialDate: Date;
        finalDate: Date;
        scale?: number;
      };
    };

export function loadDashboardHectaresGraph(
  initialDate: Date,
  finalDate: Date,
  scale?: number
): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: DASHBOARD_LOAD_HECTARES,
    });

    const result = await DashboardService.getHectaresData(initialDate, finalDate, scale);

    if (!result.success) {
      dispatch({
        type: DASHBOARD_LOAD_HECTARES_ERROR,
        payload: {
          error: result.error,
        },
      });
      return;
    }

    dispatch({
      type: DASHBOARD_LOAD_HECTARES_SUCCESS,
      payload: {
        data: result.data,
        initialDate: initialDate,
        finalDate: finalDate,
        scale: scale,
      },
    });
  };
}

export function loadDashboardIncomeGraph(
  initialDate: Date,
  finalDate: Date,
  scale?: number
): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: DASHBOARD_LOAD_INCOME,
    });

    const result = await DashboardService.getIncomeData(initialDate, finalDate, scale);

    if (!result.success) {
      dispatch({
        type: DASHBOARD_LOAD_INCOME_ERROR,
        payload: {
          error: result.error,
        },
      });
      return;
    }

    dispatch({
      type: DASHBOARD_LOAD_INCOME_SUCCESS,
      payload: {
        data: result.data,
        initialDate: initialDate,
        finalDate: finalDate,
        scale: scale,
      },
    });
  };
}
