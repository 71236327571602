import { applyMiddleware, compose, createStore } from "redux";
import Reactotron from "../ReactotronConfig";
import thunk from "redux-thunk";
import {
  registerUnauthorizedErrorCallback,
  setBiohubAuthorizationToken,
} from "../services/axios/BiohubApi";
import { logOut, LOG_OUT } from "./actions/loginActions";
import { systemReducer, SystemState } from "./reducers/systemReducer";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistConfig } from "redux-persist/es/types";
import { persistReloadProfile } from "./actions/profileActions";

/// This file is where a store is created using our system reducer.
///
/// The store is available for use (1) inside components using react-redux and (2) outside
/// components by using the object exported in this file.

//const store = createStore(systemReducer, applyMiddleware(thunk));

const persistConfig: PersistConfig<any> = {
  key: "root",
  storage,
  whitelist: ["login", "locale", "profile", "dashboard", "collections"],
};

const persistConfiguredReducer = persistReducer(persistConfig, systemReducer);

const store = createStore(
  persistConfiguredReducer,
  compose(applyMiddleware(thunk), Reactotron.createEnhancer?.()!)
);
//const persistor = persistStore(store);

const persistor = persistStore(store, null, () => {
  // Persistor middleware to process the system state recovery
  // With the login and profile state here we get the update profile information
  const systemState: SystemState = store.getState();
  if (systemState.profile.userProfile !== null && systemState.login.token !== null) {
    // If we have the correct persist informations to get the user profile
    setBiohubAuthorizationToken(systemState.login.token);
    persistReloadProfile(store, systemState.profile.userProfile, systemState.login.token);
  } else {
    // Otherwise we logout the user
    store.dispatch({ type: LOG_OUT });
  }
});

// Here, we will also register a callback to the service layer:
registerUnauthorizedErrorCallback(() => {
  // This will be called whenever there's an unauthorized error in a request.
  // We'll immediately cause a logout, and also clear the authentication token.
  store.dispatch<any>(logOut());
});

export { store, persistor };
