/**
 * English (en)
 */
const messagesMap: { [key: string]: string } = {
  //-- Page Information --//
  "info.pageTitle": "bioHUB - NCB",
  "info.slogan": "Precise by Nature",
  "info.title": "The first and only HUB \nof technologies for \nPrecision Biological Control",
  "info.description": "Coming Soon!",
  "info.newsletter": "Subscribe our Newsletter",
  "info.about": "bioHUB by NCB - Sistemas Embarcados",
  "info.copyright": "2021 ⓒ bioHUB - NCB. All rights reserved.",
  "info.address":
    "Estrada Doutor Altino Bondensan, 500 PQTEC - CE1 - Sala 207 - Eugênio de Melo, São José dos Campos - SP, CEP: 147-016",
  "info.email": "contato@ncb.ind.br",
  "info.phone": "(11) 98371-9738",
  "info.terms":
    "By accepting the present term, the user consents and agrees that the company NCB, hereinafter referred to as the Controller, makes decisions regarding the treatment of their personal data, as well as carrying out the treatment of their personal data, involving operations such as those referring to the collection, production, reception, classification, use, access, reproduction, transmission, distribution, processing, archiving, storage, elimination, evaluation or control of information, modification, communication, transfer, dissemination or extraction. The Controller is authorized to share the Holder's personal data with other data processing agents, if necessary for the purposes listed in this term, subject to the principles and guarantees established by Law No. 13.709.",
  "info.summary": "Summary",
  "info.register.information": "Register information",
  "info.register.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.legal.information": "Legal representative information",
  "info.legal.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.plans.information": "Plans information",
  "info.amount": "Amount",
  "info.plan": "Plan",
  "info.mode": "Mode",
  "info.price": "Price",
  "info.per.year": "Per Year",
  "info.total": "Total",
  "info.users": "Users",
  "info.preregistrations": "Pre-Registrations",
  "info.preregistration": "Pre-registration",
  "info.preregistrations.viewdetails": "These are the information from this pre-registration.",
  "info.contract": "Contract",
  "info.add.new": "Enter the data bellow to add a new user.",
  "info.clients": "Customers",
  "info.termError": "Your message could not be sent at this time.",
  "info.menuOptions": "Menu Options",

  "info.aboutTitle": "What is ",
  "info.aboutNCB": "NCB?",
  "info.aboutDescription":
    "bioHub is a holistic digital platform that enables effective pest and disease management through drones and software with a focus on business intelligence. \n\n We are a pioneer in the use of precision biological control.",
  "info.aboutVisionTitle": "Our vision",
  "info.aboutVisionSub": "The reality that is sought",
  "info.aboutVisionText":
    "Transforming the agricultural sector by promoting data management and the application of technology and sustainable innovation for the creation of strategies and the assertive use of biological control. In three years, we want Bi All to be the reference in the symbiosis between agriculture, science and technology, after having operated three million hectares of crops.",
  "info.aboutMissionTitle": "Our mission",
  "info.aboutMissionSub": "The reason for being",
  "info.aboutMissionText":
    "Catalyze nature through the digitization of data that allows you to create strategies for making decisions, ensuring the best management of resources. Provide solutions in agricultural ecosystems through precision biological control.",
  "info.aboutValues": "Our values",
  "info.contactTitle": "Contact us",
  "info.contactDescription":
    "Fill in your details in the form below and wait. We will contact you as soon as possible!",
  "info.termTitle": "The bioMAPS plan",
  "info.coin": "$",
  "info.termPriceMonth": "per month / per device",
  "info.termPriceYear": "per year / per device (15% off)",
  "info.termSpecifications": "Specifications:",
  "info.termSpecificationsDesc":
    "It is DRONES' first mission planner with a focus on biological precision control. You can plan routes from anywhere in the world through the cloud and synchronized with a mobile application to launch 100% GPS tracked and controlled bios.",
  "info.termClassifications": "Classifications: ",
  "info.termClassificationsDesc":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis curae cursus per, morbi neque viverra nam nascetur mauris metu consequat quis.",
  "info.termChoose": "Choose plan",
  "info.introducing": "Introducing",
  "info.cameraQuality": "Camera Quality",
  "info.flightTime": "Flight Time",
  "info.capacity": "Capacity",
  "info.minutes": "minutes",
  "info.verificationTitle": "E-mail Verification",
  "info.verificationDescription":
    "Verification requires a valid email address. \nWe will send a confirmation code to the email address you entered in order to start the validation process. \nIf the address given below is incorrect, click on the icon and change your details.",
  "info.emailAddress": "E-mail Address",
  "info.verificationCodeDescription": "Informe o código enviado para seu e-mail:",
  "info.confirmationCode": "Código de verificación",
  "info.newPassword": "New Password",
  "info.newPasswordDescription": "Please set a new password to access our platform.",
  "info.newPasswordDetails": "Enter the new password",
  "info.newPasswordConfirm": "Confirm your new password",

  "prod.biocoteDescription":
    "It is a biodegradable cup releaser with cothesia mass used mainly in the population control of caterpillars. Sugarcane is the main crop treated. (Biocontrol's exclusive development). 100% operational and commercial. Your version of parasitized unique pasta has been frozen by we cannot continue field testing, we will resume from January 2021.",
  "prod.usageSugarcane": "Sugarcane",
  "prod.usageForest": "Forest",
  "prod.usageCotton": "Cotton",
  "prod.usageCorn": "Corn",
  "prod.usageSoybeans": "Soybeans",

  //-- Landing Page --//
  "page.title": "Digitize your field, \nthe first step towards \nthe future of farming",
  "page.description":
    "bioHUB is a holistic digital platform that alows effective management of pests and diseases through the use of drones and software focused on business intelligence.",
  // Sections
  "section.about.title": "Our Products",
  "section.about.category": "Software",
  "section.about.info.title": "About bioHUB",
  "section.about.info.subtitle": "Precision biological control management system",
  "section.about.info.description":
    "An integrated cloud platform that assists the entire biological control chain in monitoring pests, accurately releasing, generating reports and monitoring decision-making.",
  "section.biomaps.title": "About BioMAPS:",
  "section.biomaps.subtitle": "Flight Mission Planner",
  "section.biomaps.description":
    "The first mission planner for DRONES focused on precision biological control. Plan routes from anywhere in the world.",
  "section.products.category": "Hardware",
  "section.prices.category": "Pricing",
  "section.prices.title": "The right plan for your business",
  "section.prices.description":
    "We offer several powerful plans to showcase your business and be discovered as a creative entrepreneur.",
  "section.clients.category": "Clients",
  "section.clients.title": "What our customers have to say",
  "section.partners.category": "Partners",

  //-- Dashboard Information --//
  "dashboard.welcome": "Welcome back, ",
  "dashboard.clients": "Clients",
  "dashboard.users": "Users",
  "dashboard.total.users": "Total Users",
  "dashboard.total.clients": "Total Clients",
  "dashboard.total.drones": "Total Drones",
  "dashboard.total.projects": "Total Projects",
  "dashboard.total.sales": "Total Sales",
  "dashboard.total.area": "Total Hectares Covered",
  "dashboard.total.yearMonthLegend": "Month / Year",

  "dashboard.hectares.covered": "Hectares Covered",
  "dashboard.new.company": "New companies",
  "dashboard.flight.client": "Flights per customer",
  "dashboard.coverage.graph": "Coverage Area Graph",
  "dashboard.scale": "Scale",
  "dashboard.graphmodeview": "View by",
  "dashboard.filter": "Filter",
  "dashboard.time.30minutes": "30 minutes",
  "dashboard.time.1hour": "1 hour",
  "dashboard.time.8hours": "8 hours",
  "dashboard.time.1day": "1 day",
  "dashboard.time.1week": "1 week",
  "dashboard.view.general": "-",
  "dashboard.view.profile": "Profile",
  "dashboard.view.client": "Client",
  "dashboard.view.drone": "Drone",

  //-- Menu Information --//
  "menu.about": "About NCB",
  "menu.products": "Products",
  "menu.pricing": "Pricing",
  "menu.contact": "Contact",
  "menu.privacy": "Privacy Policy",
  "menu.terms": "Terms & Conditions",
  "menu.title": "Company",
  "menu.support": "Support",
  "menu.faq": "FAQ's",
  "menu.resources": "Resources",
  "menu.why": "Why bioHUB?",
  "menu.home": "Home",
  "menu.biomaps": "bioMAPS",
  "menu.profile": "Profile",
  "menu.plans": "Plans",
  "menu.settings": "Settings",
  "menu.open.project": "Open Project",
  "menu.new.project": "New Project",
  "menu.new.monitoring": "New Monitoring",
  "menu.flight.monitoring": "Flight Monitoring",
  "menu.trap.monitoring": "Trap Monitoring",
  "menu.biomapsInfo":
    "BioMAP visualizes the areas where biological controllers are released through the mission planner.",
  "menu.licenses": "Licenses",
  "menu.traps": "Traps",

  //-- Generic Actions --//
  "action.login": "Login",
  "action.signUp": "Sign Up",
  "action.subscribe": "Subscribe",
  "action.register": "Pre-register",
  "action.makeRegistration": "Make your pre-registration!",
  "action.success": "Success",
  "action.error": "We're sorry!",
  "action.msgSuccess":
    "Your pre-registration was successful in our database! \nWe'll notify you soon ✓",
  "action.msgError": "There was an error during your pre-registration! Please try again later.",
  "action.back": "Back",
  "action.getStarted": "Get Started",
  "action.learnMore": "Learn more",
  "action.more": "More information...",
  "action.keep.connected": "Keep connected",
  "action.login.message": "Enter your details to login",
  "action.login.msgError": "Error signing in. Please check your data or try again in a moment.",
  "action.continue": "Continue",
  "action.accept": "Accept the terms",
  "action.indicator": "Register as: ",
  "action.signup.company": "Company",
  "action.signup.single": "Single person",
  "action.accept.signup.terms": "I Accept the general terms of the LGPD data protection law.",
  "action.pay.now": "Pay Now",
  "action.add.new": "Add new",
  "action.edit": "Edit",
  "action.edit.picture": "Change picture",
  "action.remove": "Remove",
  "action.save": "Save",
  "action.cancel": "Cancel",
  "action.undo": "Undo",
  "action.finish": "Finish",
  "action.settings": "Settings",
  "action.income": "Income",
  "action.send": "Send",
  "action.confirm": "Confirm",
  "action.sendVerification": "Send code verification",
  "action.saveData": "Save data",

  //-- Placeholders --//
  "placeholder.profile": "Profile",
  "placeholder.email": "E-mail",
  "placeholder.name": "Name",
  "placeholder.phone": "Phone number",
  "placeholder.company": "Company",
  "placeholder.data.collect": "How did you hear about NCB?",
  "placeholder.password": "Password",
  "placeholder.search": "Search",
  "placeholder.iva": "VAT",
  "placeholder.company.name": "Company name",
  "placeholder.zip": "ZIP",
  "placeholder.address": "Address",
  "placeholder.number": "Number",
  "placeholder.complement": "Complement",
  "placeholder.country": "Country",
  "placeholder.state.department": "State",
  "placeholder.city": "City",
  "placeholder.comercial.phone": "Comercial phone",
  "placeholder.aviation.reg": "Aviation Registration",
  "placeholder.agriculture.reg": "Agriculture Registration",
  "placeholder.role": "Role",
  "placeholder.document": "Document",
  "placeholder.rg": "RG",
  "placeholder.crea": "CREA",
  "placeholder.ie": "I.E.",
  "placeholder.im": "I.M.",
  "placeholder.trade.name": "Trade Name",
  "placeholder.type": "Type",
  "placeholder.contact": "Contact",
  "placeholder.description": "Bio",
  "placeholder.message": "Message",
  "placeholder.project": "Project",
  "placeholder.area": "Area",
  "placeholder.aircraft": "Aircraft",

  "profile.selectedUser": "selected",

  "profile.account": "My Account",
  "profile.profile": "My Profile",

  //-- Options --//
  "option.preferences.generalinfo": "General information",
  "option.preferences.unitsystem": "Unit system",
  "option.preferences.twofactorauth": "Two-factor authentication",
  "option.preferences.sexagesimalcoordinates": "Use Sexagesimal Coordinates",
  "option.preferences.separatelybatteries": "Show Batteries Separately",
  "option.preferences.metric": "Metric",
  "option.preferences.imperial": "Imperial",
  "option.search.engine": "Search engine",
  "option.facebook": "Facebook",
  "option.linkedin": "Linkedin",
  "option.recommendation": "Recommendations",
  "option.email.marketing": "E-mail Marketing",
  "option.events": "Events",
  "option.others": "Others",
  "option.which": "Which?",

  //-- Map --//
  "map.title": "Map",
  "map.defaultRegion": "US",
  "map.editMode.addPolygon":
    "Touch the map to insert a point; long-press a marker to change its position.",
  "map.editMode.error.theePointsMinimum": "Please, input at least three points.",
  "map.editMode.error.interesection":
    "Inserted polygon has one or more intersections. Please draw a simple polygon.",
  "map.removeArea": "Remove this area?",
  "map.newArea": "New area",
  "map.loadingList": "loading...",
  "map.editPolygonPoints": "Edit points",
  "map.editAreaProperties": "Edit area",
  "map.deleteArea": "Delete area",
  "map.importAreas.importAreasAction": "Import areas",
  "map.newArea.createAreaModalTitle": "Create new area",
  "map.newArea.importAreaModalTitle": "Create new area",
  "map.newArea.areaName": "Area name",
  "map.newProject.title": "New project",
  "map.newProject.projectName": "New project",
  "map.newProject.optionalImportKml": "Import file",
  "map.newProject.kmlFileChoose": "Choose...",
  "map.newProject.releaser": "Releaser",
  "map.newProject.input": "Input",
  "map.newProject.releaseRate": "Release rate",
  "map.newProject.instantaneousReleaseRate": "Instantaneous release rate",
  "map.type.roadmap": "Roadmap",
  "map.type.satellite": "Satellite",
  "map.type.hybrid": "Hybrid",
  "map.areasAmountSing": "{amount} area",
  "map.areasAmountPl": "{amount} areas",

  //-- Route planning --//
  "routePlanning.param.areaPadding": "Path margin",
  "routePlanning.param.flightHeight": "Flight height",
  "routePlanning.param.flightSpeed": "Flight speed",
  "routePlanning.param.trackWidth": "Track width",
  "routePlanning.param.considerRelief": "Consider terrain relief",

  "routePlanning.param.waypoint.height": "Height",
  "routePlanning.param.waypoint.curveRadius": "Curve radius",
  "routePlanning.param.waypoint.speed": "Speed",
  "routePlanning.param.waypoint.orientation": "Orientation",

  "routePlanning.param.waypoint.actions": "Actions",
  "routePlanning.param.waypoint.action.enableEquipment": "Enable Equipment",
  "routePlanning.param.waypoint.action.disableEquipment": "Disable Equipment",
  "routePlanning.param.waypoint.action.none": "None",

  //-- LANGUAGES --//
  language: "Language",
  "language.pt": "Brazilian Portuguese",
  "language.es": "Spanish",
  "language.en": "English",
  "language.fr": "French",
  "language.name": "English",
  "language.region": "EN",
  "language.decimalSeparator": ".",

  //- Reports -//
  "report.issueDate": "Issue Date:",
  "report.area": "Area",
  "report.plannedFlight": "Planned Flight",
  "report.details": "Details",
  "report.dateHour": "Date and Hour:",
  "report.drone": "Drone:",
  "report.manufacturer": "Manufacturer:",
  "report.anac": "ANAC:",
  "report.releasedProduct": "Released Product:",
  "report.releasing": "Releasing",
  "report.bandWidth": "Bandwidth",
  "report.plannedSpeed": "Planned Speed",
  "report.releaseRate": "Release Rate",
  "report.realFlight": "Real Flight",
  "report.flightTime": "Flight Time",
  "report.averageReleaseRate": "Average Release Rate",
  "report.averageSpeed": "Average Speed",
  "report.totalReleased": "Total Released",
  "report.totalArea": "Total Area",
  "report.traps": "Traps",
  "report.serialNumber": "Serial Number",
  "report.coveredHectares": "Covered Hectares",
  "report.dateMeasurament": "Last Measurement Date",
  "report.specie": "Species",
  "report.adultsNumber": "Adults Number",
  "report.noAvailable": "No available items",
  "report.mapRecord": "Map Record:",
  "report.contractor": "Contractor:",
  "report.property": "Property:",
  "report.localization": "Localization:",
  "report.county": "County:",
  "report.uf": "UF:",
  "report.doc": "CNPJ/CPF:",
  "report.serviceType": "Service Type:",
  "report.product": "Product",
  "report.formulation": "Formulation",
  "report.dosage": "Dosage",
  "report.toxicClass": "Toxic. Class",
  "report.adjuvant": "Adjuvant",
  "report.culture": "Culture: ",
  "report.volume": "Volume (liters or kg / ha):",
  "report.others": "Outhers: ",
  "report.agronomicalRecipe": "Agronomical Recipe n:",
  "report.issuedOn": "Issued on:",
  "report.basicParameter": "Basic Application Parameters",
  "report.maxTemperature": "Max. Temperature.=",
  "report.relativeUnitMin": "Min Relative Unit.=",
  "report.equipment": "Equipment=",
  "report.model": "Model=",
  "report.type": "Type=",
  "report.angle": "Angle=",
  "report.flightHeight": "Flight height=",
  "report.bandwidth": "Bandwidth (m)=",
  "report.date": "Date",
  "report.signatureCREA": "Name, signature and CREA of the professional",
  "report.weatherConditions": "Weather Conditions in the Application",
  "report.morning": "Morning",
  "report.evening": "Evening",
  "report.applicationStart": "Application start: hours and end: hours",
  "report.initial": "Initial",
  "report.final": "Final",
  "report.temperature": "Temperature",
  "report.relativeHumidity": "Relative Humidity",
  "report.aircraftPrefixe": "Prefixe - Aircraft:",
  "report.dgpsUse": "DGPS Use: Yes or No",
  "report.windSpeed": "Wind Speed",
  "report.trackCoordinates": "Track Coordinates:",
  "report.observation": "Observation:",
  "report.signatureEng": "Name, signature and CREA of Eng. Agronomo:",
  "report.print": "Imprimir",

  // Pre-registration research
  "preregistration.interest.question":
    "What do you think is most important in\nPrecision Biological Control?",
  "preregistration.interest.financial": "Financial",
  "preregistration.interest.financial.cost": "Operation cost",
  "preregistration.interest.financial.savings": "Input savings",
  "preregistration.interest.financial.pest": "Pest reduction",
  "preregistration.interest.financial.production": "Production increase",

  "preregistration.interest.sustainability": "Sustainability",
  "preregistration.interest.sustainability.carbonfootprint": "Reduction of carbon footprint",
  "preregistration.interest.sustainability.pesticides": "Reduction of pesticides",
  "preregistration.interest.sustainability.environment": "Environment",
  "preregistration.interest.sustainability.water": "Water economy",

  "preregistration.interest.reliability": "Reliability",
  "preregistration.interest.reliability.precision": "Precision",
  "preregistration.interest.reliability.releasingrate": "Releasing rate",
  "preregistration.interest.reliability.tracking": "Route tracking",
  "preregistration.interest.reliability.coverage": "Area coverage",
};

export default messagesMap;
