import React from "react";
import ReactDOM from "react-dom";
import Root from "./navigation/Root";

// Styles
import "./css/colors.css";
import "./css/styles.css";

ReactDOM.render(
  // <React.StrictMode>
  <Root />,
  // {/* </React.StrictMode>, */}
  document.getElementById("root")
);
