import React from "react";
import { useIntl } from "react-intl";
import Input from "../../Inputs/Field";
import Select from "../../Inputs/Select";
import Checkbox from "../../Inputs/Checkbox";
import Text from "../../BasicComponents/Text";
import Button from "../../BasicComponents/Button";
import Image from "../../BasicComponents/Image";
import FlipCard from "../../BasicComponents/FlipCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles"; //set custom color required
import { teal } from "@material-ui/core/colors"; //set custom colors
import { Form, Fields, Group, Loading, Terms, Accept, ToBack } from "./styles";

import PreRegisterService from "../../../../services/PreRegisterService";

/* Images */
import imageSuccess from "../../../../assets/icon/check-mark.svg";
import imageError from "../../../../assets/icon/icon_warning.svg";
import {
  Grid,
  Checkbox as MaterialCheckBox,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Box,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import Radio, { RadioProps } from "@material-ui/core/Radio";

const researchSurvey: Array<{ intlTagName: string; intlTagOptions: Array<string> }> = [
  {
    intlTagName: "preregistration.interest.financial",
    intlTagOptions: [
      "preregistration.interest.financial.cost",
      "preregistration.interest.financial.savings",
      "preregistration.interest.financial.pest",
      "preregistration.interest.financial.production",
    ],
  },
  {
    intlTagName: "preregistration.interest.sustainability",
    intlTagOptions: [
      "preregistration.interest.sustainability.carbonfootprint",
      "preregistration.interest.sustainability.pesticides",
      "preregistration.interest.sustainability.environment",
      "preregistration.interest.sustainability.water",
    ],
  },
  {
    intlTagName: "preregistration.interest.reliability",
    intlTagOptions: [
      "preregistration.interest.reliability.precision",
      "preregistration.interest.reliability.releasingrate",
      "preregistration.interest.reliability.tracking",
      "preregistration.interest.reliability.coverage",
    ],
  },
];

interface Props {}

export default () => {
  const intl = useIntl();
  const [active, setActive] = React.useState<boolean>(false); //if true = show message
  const [response, setResponse] = React.useState<boolean>(false); //if true = success message
  const [loading, setLoading] = React.useState<boolean>(false); //if true = show loading
  const [viewTerms, setViewTerms] = React.useState<boolean>(false); //if true = show terms
  const [checked, setChecked] = React.useState<boolean>(true); //TODO: if true = accept the terms

  const [selectResearchSurvey, setResearchSurvey] = React.useState<number>(-1);

  const [formData, setFormData] = React.useState<{
    name: string;
    email: string;
    companyName: string;
    phoneNumber: string;
    surveyAnswer: string;
    markedSurveySelection: Array<number>;
  }>({
    name: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    surveyAnswer: "",
    markedSurveySelection: [-1, -1],
  });

  const optionsList = [
    {
      id: "search engine",
      title: intl.formatMessage({ id: "option.search.engine" }),
    },
    {
      id: "facebook",
      title: intl.formatMessage({ id: "option.facebook" }),
    },
    {
      id: "linkedin",
      title: intl.formatMessage({ id: "option.linkedin" }),
    },
    {
      id: "recommendation",
      title: intl.formatMessage({ id: "option.recommendation" }),
    },
    {
      id: "marketing",
      title: intl.formatMessage({ id: "option.email.marketing" }),
    },
    {
      id: "events",
      title: intl.formatMessage({ id: "option.events" }),
    },
    {
      id: "other",
      title: intl.formatMessage({ id: "option.others" }),
    },
  ];

  function validateImage(type: boolean): string {
    //define an icon or image according to the response returned when pre-registering
    switch (type) {
      case true:
        return imageSuccess;
      case false:
        return imageError;
    }
  }

  function validateType(type: boolean): string {
    //informs the type of message to be displayed when completing the pre-registration function
    switch (type) {
      case true:
        return intl.formatMessage({ id: "action.success" });
      case false:
        return intl.formatMessage({ id: "action.error" });
    }
  }

  function validateMessage(type: boolean) {
    //informs the message that will be displayed when completing the pre-registration function
    switch (type) {
      case true:
        return intl.formatMessage({ id: "action.msgSuccess" });
      case false:
        return intl.formatMessage({ id: "action.msgError" });
    }
  }

  //add custom color checkbox
  const CustomCheckBox = withStyles({
    root: {
      color: teal[50],
      "&$checked": {
        color: teal["A400"],
      },
    },
    checked: {},
  })((props: CheckboxProps) => <MaterialCheckBox color="default" {...props} />);

  const CustomRadio = withStyles({
    root: {
      color: teal[50],
      "&$checked": {
        color: teal["A400"],
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

  return (
    <>
      <Form>
        <FlipCard
          height={viewTerms ? undefined : selectResearchSurvey !== -1 ? 615 : 440}
          active={active}
          frontChildren={[
            !viewTerms
              ? [
                  <Text
                    text={intl.formatMessage({ id: "action.makeRegistration" })}
                    appearance={"title"}
                    color={"light"}
                    size={"medium"}
                    textAlign={"center"}
                  />,
                  <Fields>
                    <Input
                      placeholder={intl.formatMessage({ id: "placeholder.name" })}
                      type={"text"}
                      required
                      value={formData.name}
                      onChange={(s) =>
                        setFormData({
                          ...formData,
                          name: s,
                        })
                      }
                    />
                    <Input
                      placeholder={intl.formatMessage({ id: "placeholder.email" })}
                      type={"email"}
                      required
                      value={formData.email}
                      onChange={(s) =>
                        setFormData({
                          ...formData,
                          email: s,
                        })
                      }
                    />
                    <Group>
                      <Input
                        placeholder={intl.formatMessage({ id: "placeholder.phone" })}
                        type={"tel"}
                        required
                        value={formData.phoneNumber}
                        onChange={(s) =>
                          setFormData({
                            ...formData,
                            phoneNumber: s,
                          })
                        }
                      />
                      <Input
                        placeholder={intl.formatMessage({ id: "placeholder.company" })}
                        type={"text"}
                        required
                        value={formData.companyName}
                        onChange={(s) =>
                          setFormData({
                            ...formData,
                            companyName: s,
                          })
                        }
                      />
                    </Group>
                    <Select
                      placeholder={intl.formatMessage({ id: "placeholder.data.collect" })}
                      key={"data-collect"}
                      options={optionsList}
                      onChange={(s) =>
                        setFormData({
                          ...formData,
                          surveyAnswer: s as string,
                        })
                      }
                      required
                    />
                  </Fields>,
                  <>
                    <Text
                      text={intl.formatMessage({ id: "preregistration.interest.question" }) + "*"}
                      color={"light"}
                      textAlign={"center"}
                    />
                    <Box height={10} />
                    <Grid container spacing={1} className={"question-btns"}>
                      {researchSurvey.map(function (option, index) {
                        const termOption: string = intl.formatMessage({ id: option.intlTagName });
                        return (
                          <Grid item xs={4}>
                            <Button
                              action={() => {
                                if (index === selectResearchSurvey) {
                                  setResearchSurvey(-1);
                                } else {
                                  setResearchSurvey(index);
                                }
                              }}
                              color={
                                index === selectResearchSurvey
                                  ? "highlighted"
                                  : index === formData.markedSurveySelection[0]
                                  ? "inherit"
                                  : "secondary"
                              }
                            >
                              {termOption}
                            </Button>
                          </Grid>
                        );
                      })}
                    </Grid>

                    <Grid container spacing={0} className={"mt-20"}>
                      {selectResearchSurvey !== -1 &&
                        researchSurvey[selectResearchSurvey].intlTagOptions.map(function (
                          option,
                          index
                        ) {
                          const labelId: string = selectResearchSurvey.toString() + option;
                          //const isItemSelected: boolean = formData.markedOptions.includes(index);
                          const isItemSelected: boolean =
                            formData.markedSurveySelection[0] === selectResearchSurvey &&
                            formData.markedSurveySelection[1] === index;
                          const termOption = intl.formatMessage({
                            id: researchSurvey[selectResearchSurvey].intlTagOptions[index],
                          });
                          return (
                            <>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  alignSelf: "center",
                                }}
                                className={"select-item"}
                              >
                                <RadioGroup
                                  aria-label={termOption}
                                  name={termOption}
                                  value={labelId}
                                  // onChange={() => {
                                  //   const newFormData = { ...formData };
                                  //   if (!isItemSelected) {
                                  //     if (newFormData.markedOptions.length == 2) {
                                  //       newFormData.markedOptions.splice(0, 1);
                                  //     }
                                  //     newFormData.markedOptions.push(index);
                                  //   } else {
                                  //     const itemIndex = newFormData.markedOptions.indexOf(index, 0);
                                  //     if (itemIndex > -1) {
                                  //       newFormData.markedOptions.splice(itemIndex, 1);
                                  //     }
                                  //   }
                                  //   setFormData(newFormData);
                                  // }}
                                >
                                  <FormControlLabel
                                    value={labelId}
                                    control={
                                      <CustomRadio
                                        checked={isItemSelected}
                                        onClick={() => {
                                          const newFormData = { ...formData };
                                          if (!isItemSelected) {
                                            newFormData.markedSurveySelection = [
                                              selectResearchSurvey,
                                              index,
                                            ];
                                          } else {
                                            newFormData.markedSurveySelection = [-1, -1];
                                          }
                                          setFormData(newFormData);
                                        }}
                                        value={labelId}
                                        name="radio-button"
                                        inputProps={{ "aria-labelledby": labelId }}
                                      />
                                    }
                                    label={termOption}
                                  />
                                </RadioGroup>
                              </Grid>
                            </>
                          );
                        })}
                    </Grid>
                  </>,
                  <Accept>
                    <Button
                      text={intl.formatMessage({ id: "action.continue" })}
                      color={"secondary"}
                      size={"large"}
                      action={() => {
                        function empty(s: string): boolean {
                          return !s || s.length == 0;
                        }
                        console.log("formData:");
                        console.log(formData);
                        // Return immediately if there isn't sufficient data.
                        if (
                          empty(formData.email) ||
                          empty(formData.name) ||
                          empty(formData.phoneNumber) ||
                          empty(formData.companyName) ||
                          empty(formData.surveyAnswer) ||
                          formData.markedSurveySelection[0] === -1 ||
                          formData.markedSurveySelection[1] == -1
                        ) {
                          return;
                        }

                        setViewTerms(true);
                      }}
                    />
                  </Accept>,
                ]
              : [
                  <Terms>
                    <Text text={intl.formatMessage({ id: "info.terms" })} color={"light"} />
                    <Accept>
                      <Checkbox
                        label={intl.formatMessage({ id: "action.accept" })}
                        color={"light"}
                      />

                      <Button
                        text={intl.formatMessage({ id: "action.register" })}
                        color={"secondary"}
                        size={"large"}
                        action={() => {
                          function empty(s: string): boolean {
                            return !s || s.length == 0;
                          }
                          // Return immediately if there isn't sufficient data.
                          if (
                            empty(formData.email) ||
                            empty(formData.name) ||
                            empty(formData.phoneNumber) ||
                            empty(formData.companyName) ||
                            empty(formData.surveyAnswer)
                          ) {
                            return;
                          }
                          console.log(`Sending data: ${formData}`);
                          setLoading(true);
                          const marketResearchSurvey: Array<string> = [
                            intl.formatMessage({
                              id: researchSurvey[formData.markedSurveySelection[0]].intlTagName,
                            }),
                            intl.formatMessage({
                              id: researchSurvey[formData.markedSurveySelection[0]].intlTagOptions[
                                formData.markedSurveySelection[1]
                              ],
                            }),
                          ];
                          // if (selectResearchSurvey !== -1) {
                          //   marketResearchSurvey.push(
                          //     intl.formatMessage({
                          //       id: researchSurvey[selectResearchSurvey].intlTagName,
                          //     })
                          //   );
                          //   formData.markedOptions.forEach(function (index: number) {
                          //     const termOption = intl.formatMessage({
                          //       id: researchSurvey[selectResearchSurvey].intlTagOptions[index],
                          //     });
                          //     marketResearchSurvey.push(termOption);
                          //   });
                          // }

                          PreRegisterService.preRegister(
                            formData.email,
                            formData.name,
                            formData.phoneNumber,
                            formData.companyName,
                            formData.surveyAnswer,
                            marketResearchSurvey
                          )
                            .then((res) => {
                              setResponse(res.success);
                              if (!res.success) {
                                console.log("Error with pre-registration request.");
                                console.log(res.error);
                              }
                              setActive(!active);
                            })
                            .catch((e) => {
                              // Unexpected exception thrown.
                              setResponse(false);
                              setActive(!active);
                              console.log(
                                "Unexpected exception thrown for pre-registration request."
                              );
                              console.log(e);
                            });
                        }}
                      />
                    </Accept>
                    <ToBack>
                      <Button
                        text={intl.formatMessage({ id: "action.back" })}
                        appearance={"button-link"}
                        size={"small"}
                        // Returns the card to the starting position
                        action={() => {
                          setViewTerms(!viewTerms);
                        }}
                      />
                    </ToBack>
                  </Terms>,
                ],
            <Loading>{loading && <CircularProgress />}</Loading>,
          ]}
          backChildren={[
            <Image
              src={validateImage(response)}
              alt={intl.formatMessage({ id: "info.slogan" })}
              appearance={"img-logo"}
            />,
            <Text
              text={validateType(response)}
              appearance={"title"}
              color={"light"}
              size={"medium"}
              transform={"uppercase"}
              textAlign={"center"}
            />,
            <Text text={validateMessage(response)} color={"light"} textAlign={"center"} />,
            <Button
              text={intl.formatMessage({ id: "action.back" })}
              appearance={"button-link"}
              size={"small"}
              // Returns the card to the starting position
              action={() => {
                setActive(!active);
                setLoading(false); //TODO: temporary loading activation. How will it be activated?
              }}
            />,
          ]}
        />
      </Form>
    </>
  );
};
