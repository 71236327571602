import styled from "styled-components";
import Icons from "@material-ui/core/Icon";

type Props = {
  type:
    | string
    | "facebook"
    | "linkedin"
    | "instagram"
    | "twitter"
    | "icon-whatsapp"
    | "icon-home"
    | "icon-biomaps"
    | "icon-profile"
    | "icon-plans"
    | "icon-settings"
    | "icon-folder"
    | "icon-list"
    | "icon-map"
    | "icon-drone"
    | "icon-trap";
};

export const Icon = styled(Icons).attrs((props: Props) => ({
  type: props.type,
}))``;
