import React from "react";
import { Router } from "react-router-dom";
import history from "./history";
import { IntlProvider } from "react-intl";

import Routes from "./Routes";
import { messagesForLocale } from "../localization";
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "../store";
import { BiohubLocale } from "../store/reducers/localeReducer";
import { SystemState } from "../store/reducers/systemReducer";
import HttpsRedirect from "./HttpsRedirect";
import { PersistGate } from "redux-persist/integration/react";

// TODO: Read the locale from the user's preferences.
const locale = "pt-BR";

/**
 * Items in this declaration:
 *
 * Provider - react-redux's store provider, injects the store for all components.
 * IntlProvider - Internationalization provider.
 * Router - Declares all navigations and decides which page to render at any given time.
 */
function Root(): JSX.Element {
  return (
    <HttpsRedirect>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizedRoot />
        </PersistGate>
      </Provider>
    </HttpsRedirect>
  );
}

/**
 * For use in Root only. Gets the locale from the store to pass it to IntlProvider.
 */
function LocalizedRoot(): JSX.Element {
  const locale: BiohubLocale = useSelector((state: SystemState) => state.locale.localeCode);
  return (
    <IntlProvider messages={messagesForLocale(locale)} locale={locale} defaultLocale="en">
      <Router history={history}>
        <Routes />
      </Router>
    </IntlProvider>
  );
}

export default Root;
