import { combineReducers } from "redux";
import { localeReducer } from "./localeReducer";
import { loginReducer } from "./loginReducer";
import { plansReducer } from "./plansReducer";
import { projectReducer } from "./projectReducer";
import { profileReducer } from "./profileReducer";
import { collectionsReducer } from "./collectionsReducer";
import { dashboardReducer } from "./dashboardReducer";

/// This file combines all reducers into a single root reducer, which we call the "System Reducer".

export const systemReducer = combineReducers({
  plans: plansReducer,
  locale: localeReducer,
  login: loginReducer,
  project: projectReducer,
  profile: profileReducer,
  collections: collectionsReducer,
  dashboard: dashboardReducer,
});

export type SystemState = ReturnType<typeof systemReducer>;
