import { Area, Location, Project, Waypoint } from "biohub-model";
import { SystemThunk } from "../systemThunk";
import MissionPlanner from "mission-planner";
import ProjectService from "../../services/ProjectService";
import { BiohubError } from "../../services/axios/BiohubApi";
import {
  boundingBoxForAreas,
  chooseDefaultHomePoint,
  fixHeadingForWaypoints,
} from "../../pages/map/geometricFunctions";
import { store } from "..";
import { SystemState } from "../reducers/systemReducer";
import ElevationService from "../../services/routePlanner/ElevationService";

// Action constants
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";
export const UPDATE_SELECTED_PROJECT = "UPDATE_SELECTED_PROJECT";
export const UPDATE_AREAS_LIST = "UPDATE_AREAS_LIST";
export const CLOSE_PROJECT = "CLOSE_PROJECT";
export const SET_SELECTED_AREA = "SET_SELECTED_AREA";
export const REQUEST_PLANNED_ROUTE = "REQUEST_PLANNED_ROUTE";
export const SET_PLANNED_ROUTE = "SET_PLANNED_ROUTE";
export const SET_PROJECT_LIST_REQUEST = "SET_PROJECT_LIST_REQUEST";
export const SET_PROJECT_LIST_SUCCESS = "SET_PROJECT_LIST_SUCCESS";
export const SET_PROJECT_LIST_FAILURE = "SET_PROJECT_LIST_FAILURE";
export const ADD_PROJECT_REQUEST = "ADD_PROJECT_REQUEST";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAILURE = "ADD_PROJECT_FAILURE";
export const CLEAR_ELEVATION_DATA = "CLEAR_ELEVATION_DATA";
export const SET_ELEVATION_DATA = "SET_ELEVATION_DATA";
export const APPEND_TO_ALL_AREAS_LIST = "APPEND_TO_ALL_AREAS_LIST";

// Action types
export type ProjectAction =
  | {
      type: typeof SET_SELECTED_PROJECT;
      payload: {
        project: Project;
        projectAreas: Area[];
      };
    }
  | {
      type: typeof UPDATE_SELECTED_PROJECT;
      payload: {
        newProjectDiff: Partial<Project>;
      };
    }
  | {
      type: typeof UPDATE_AREAS_LIST;
      payload: {
        projectId: string;
        projectAreas: Area[];
        selectedAreaId: string;
      };
    }
  | {
      type: typeof CLOSE_PROJECT;
    }
  | {
      type: typeof SET_SELECTED_AREA;
      payload: {
        areaId: string;
      };
    }
  | {
      type: typeof REQUEST_PLANNED_ROUTE;
      payload: {
        areaId: string;
        /** Optionally specify a new home point for the new route. */
        newHomePoint?: Location;
      };
    }
  | {
      type: typeof SET_PLANNED_ROUTE;
      payload: {
        areaId: string;
        plannedRoute: Array<Waypoint>;
        newHomePoint?: Location;
      };
    }
  | {
      type: typeof SET_PROJECT_LIST_REQUEST;
    }
  | {
      type: typeof SET_PROJECT_LIST_SUCCESS;
      payload: {
        projects: Project[];
      };
    }
  | {
      type: typeof SET_PROJECT_LIST_FAILURE;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof CLEAR_ELEVATION_DATA;
    }
  | {
      type: typeof SET_ELEVATION_DATA;
      payload: {
        areaId: string;
        elevationData: number[];
      };
    }
  | {
      type: typeof APPEND_TO_ALL_AREAS_LIST;
      payload: {
        projectId: string;
        areaList: Area[];
      };
    };

/**
 * Fetches all area lists, for the projects that are currently loaded.
 */
export function fetchAllAreaLists(): SystemThunk {
  return async (dispatch) => {
    const projectState = (store.getState() as SystemState).project;
    // Fire a request for every single project.
    for (var i = 0; i < projectState.projectList.length; i++) {
      const proj = projectState.projectList[i];
      // Don't await.
      ProjectService.listAreas(proj.id).then((areasResult) => {
        if (areasResult.success) {
          dispatch({
            type: APPEND_TO_ALL_AREAS_LIST,
            payload: {
              projectId: proj.id,
              areaList: areasResult.data,
            },
          });
        } // Else we just won't have the data.
      });
    }
  };
}

export function closeProject(): ProjectAction {
  return {
    type: CLOSE_PROJECT,
  };
}

/**
 * Updates the elevation data for the currently selected area's planned route.
 *
 * If the current project doesn't have a selected area, this has no effect.
 *
 * If the currently selected area doesn't have a planned path, this has no effect.
 */
export function updateElevationData(areaId: string): SystemThunk {
  return async (dispatch) => {
    const projectState = (store.getState() as SystemState).project;
    if (projectState.isProjectLoaded) {
      const areaIndex = projectState.areas.findIndex((it) => it.id === areaId);
      // If there's no area that's currently selected, this action has no effect.
      if (areaIndex !== -1) {
        const area = projectState.areas[areaIndex];
        const route = area.plannedRoute;
        // If there's no route for the currently selected area, this action has no effect.
        if (!route) {
          return;
        }

        // First, clear the elevation data right away.
        dispatch({
          type: CLEAR_ELEVATION_DATA,
        });

        // Make the request to the elevation service. This may use
        // cached data, but we can't count on that.
        const elevationData = await ElevationService.getElevationsForPath(
          route.map((waypoint) => waypoint.location)
        );
        if (elevationData.success) {
          // Set the elevation data. The reducer has to make sure that this is only
          // set if the selected area is still the same.
          dispatch({
            type: SET_ELEVATION_DATA,
            payload: {
              areaId: areaId,
              elevationData: elevationData.data,
            },
          });
        } else {
          console.warn("Error while getting elevation data.");
          console.warn(elevationData.error);
        }
      }
    }
  };
}

export function selectArea(areaId: string): SystemThunk {
  return async (dispatch) => {
    // It would actually be easier here if we received the entire area as parameter,
    // but I didn't want to require every call to this action to always have the
    // area. Besides, receiving just the id and getting the area from the state ensures
    // that we're always working with an object that's actually in the state.
    dispatch({
      type: SET_SELECTED_AREA,
      payload: {
        areaId: areaId,
      },
    });

    // Update elevation data. We expect this call to just clear the elevation data
    // and then another call to the same action should recreate the data.
    dispatch(updateElevationData(areaId));

    // Additionally, if this area doesn't have a planned path yet, we should
    // dispatch an action for requesting the planned path for this area.
    const projectState = (store.getState() as SystemState).project;
    if (projectState.isProjectLoaded) {
      const areaIndex = projectState.areas.findIndex((it) => it.id === areaId);
      if (areaIndex !== -1) {
        const areaToSelect = projectState.areas[areaIndex];
        dispatch(requestPathPlanning(areaToSelect));
      }
    }
  };
}

export function setSelectedProject(project: Project, areas: Area[]): ProjectAction {
  return {
    type: SET_SELECTED_PROJECT,
    payload: {
      project: project,
      projectAreas: areas,
    },
  };
}

/**
 * Originally, this action used to receive the list of areas, but it has been updated to
 * automatically fire all the requests needed to refetch areas.
 *
 * @param selectedAreaId The UUID of the selected area. When this is null, the first
 * area of the list will be automatically selected.
 */
export function updateAreasList(projectId: string, selectedAreaId: string | null): SystemThunk {
  return async (dispatch) => {
    const areasResult = await ProjectService.listAreas(projectId);
    if (!areasResult.success) {
      console.warn(`Failed to get areas for project ${projectId}.`);
      console.warn(areasResult.error);
      return;
    }
    const areas = areasResult.data;

    // Get a variable that's never null. When the argument value is null, this takes
    // the value of the first area's id instead, or "" when that doesn't exist either.
    const safeSelectedId = selectedAreaId ?? areas[0]?.id ?? "";

    dispatch({
      type: UPDATE_AREAS_LIST,
      payload: {
        projectId: projectId,
        projectAreas: areas,
        selectedAreaId: safeSelectedId,
      },
    });

    // It is questionable whether this thunk should also update the bounding box. But
    // there's no harm done in this request.
    const boundingBox = boundingBoxForAreas(areas);

    if (boundingBox !== null) {
      const updateProjectResult = await ProjectService.updateProject({
        id: projectId,
        boundingBox: boundingBox,
      });

      if (!updateProjectResult.success) {
        console.warn(`Failed to update project ${projectId}.`);
        console.warn(updateProjectResult.error);
      } else {
        // We're trusting that here, the project has been successfully updated. We'll set the
        // corresponding change in the local project list and in the selected project.
        dispatch({
          type: UPDATE_SELECTED_PROJECT,
          payload: {
            newProjectDiff: {
              boundingBox: boundingBox,
            },
          },
        });
      }
    }

    // Lastly, request path planning for all areas without a planned route.
    for (var i = 0; i < areas.length; i++) {
      if (areas[i].plannedRoute === null) {
        dispatch(requestPathPlanning(areas[i]));
      }
    }
  };
}

/**
 * Create a new project. The bounding box will be reassigned according to the areas, or according
 * to the map center if no areas are informed.
 *
 * Each area will also be individually added to the backend.
 */
export function createProject(project: Project, areas: Area[]): SystemThunk {
  return async (dispatch) => {
    var boundingBox:
      | {
          north: number;
          south: number;
          east: number;
          west: number;
        }
      | undefined;
    // Projects created without any areas (without importing areas) don't have a bounding box.
    boundingBox = boundingBoxForAreas(areas) ?? undefined;

    const newProject: Project = {
      ...project,
      boundingBox: boundingBox,
    };

    const newProjectResult = await ProjectService.addProject(newProject);
    if (!newProjectResult.success) {
      console.warn("Failed to create project!");
      console.warn(newProjectResult.error);
    } else {
      // Create each area.
      await Promise.all(
        areas.map(async (a) => {
          const r = await ProjectService.addArea(a);
          if (!r.success) {
            console.warn(`Adding area ${a.name} (${a.id}) failed!`);
            console.warn(r.error);
          }
        })
      );
    }

    dispatch({
      type: SET_SELECTED_PROJECT,
      payload: {
        project: newProject,
        projectAreas: areas,
      },
    });

    // Also automatically search the list of projects again.
    // Don't automatically create the planned path for all of them; it should be
    // enough to do that when selecting an area.
    dispatch(searchProjects());

    dispatch(updateAreasList(project.id, null));

    if (areas.length > 0) {
      dispatch(selectArea(areas[0].id));
    }
  };
}

export function requestPathPlanning(area: Area): SystemThunk {
  return async (dispatch) => {
    // First of all, get more info about this area from the global state.
    // (It would be nice to cancel execution if there's already a path planning in progress,
    // but that's a feature for later).

    // This will indicate a loading flag that can be used for showing an indicator somewhere.
    dispatch({
      type: REQUEST_PLANNED_ROUTE,
      payload: {
        areaId: area.id,
        newHomePoint: area.homePoint,
      },
    });

    // TODO: Do this work in a separate thread
    const plan = MissionPlanner.planRoute(
      area.polygon.map((p) => ({
        latitude: p.lat,
        longitude: p.lng,
      })),
      {
        insetDistance: area.areaConfig.areaPadding,
        lineWidth: area.areaConfig.trackWidth,
        startingPoint: area.homePoint
          ? {
              latitude: area.homePoint.lat,
              longitude: area.homePoint.lng,
            }
          : undefined,
      }
    );

    const waypoints: Array<Waypoint> = plan.map((point) => ({
      location: {
        lat: point.latitude,
        lng: point.longitude,
      },
      curveRadius: 10,
      droneActions: [],
      height: area.areaConfig.flightHeight,
      orientation: 0,
      releaserActions: {},
      speed: area.areaConfig.flightSpeed,
    }));

    // Only if the area did not have a home point before, generate a home point for it based on
    // the path.
    const newHomePoint =
      area.homePoint ?? chooseDefaultHomePoint({ ...area, plannedRoute: waypoints });

    // Fix the orientation of each waypoint so that it points to the next waypoint.
    const fixedWaypoints = fixHeadingForWaypoints(waypoints, area.homePoint);

    dispatch({
      type: SET_PLANNED_ROUTE,
      payload: {
        areaId: area.id,
        plannedRoute: fixedWaypoints,
        newHomePoint: newHomePoint,
      },
    });

    // Save remotely.
    const plannedPathResult = await ProjectService.updateArea({
      id: area.id,
      plannedRoute: fixedWaypoints,
      homePoint: newHomePoint,
    });
    if (!plannedPathResult.success) {
      console.warn(`Could not upload new planned route for area: ${area.name}`);
      console.warn(plannedPathResult.error);
    } else {
      console.log(`Uploaded planned route for area ${area.name}`);
    }

    // Update the elevation data.
    dispatch(updateElevationData(area.id));

    // Lastly, update the list of areas.
    dispatch(updateAreasList(area.projectId, area.id));
  };
}

export function searchProjects(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_PROJECT_LIST_REQUEST,
    });

    const result = await ProjectService.listProjects();
    if (result.success) {
      dispatch({
        type: SET_PROJECT_LIST_SUCCESS,
        payload: {
          projects: result.data,
        },
      });
      // After getting the list of all projects, we preemptively
      // get all lists of areas as well. The purpose is twofold:
      // when we fire the action for selecting a project later,
      // it can use a list of areas that has already been fetched
      // to skip a request, and also we can check how many areas
      // each project has without having to select it first.
      dispatch(fetchAllAreaLists());
    } else {
      dispatch({
        type: SET_PROJECT_LIST_FAILURE,
        payload: {
          error: result.error,
        },
      });
    }
  };
}
