import styled from "styled-components";
import MaterialButton from "@material-ui/core/Button";

type Props = {
  appearance?: "button-outline" | "outline-more" | "highlighted" | "button-link" | "outline-invert" |
  "button-print";
  size?: "small" | "medium" | "large";
  color?: "inherit" | "dark" | "primary" | "secondary" | "highlighted";
  position?: "left" | "right";
};

export const Button = styled(MaterialButton).attrs((props: Props) => ({
  color: props.color,
  appearance: props.appearance,
  position: props.position,
  size: props.size,
}))`
  border-radius: 50px !important;
  border: 2px solid transparent;
  margin: 0 5px;
  outline: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: "Galano Grotesque", sans-serif !important;

  .MuiButton-label {
    font-family: inherit;
    text-transform: initial;
    font-weight: normal;
    padding-bottom: 4px;
  }

  ${(props) =>
    props.color === "inherit" &&
    `
    color: var(--text-primary);
    border-color: var(--white);
    background: var(--white)!important;
  `}

  ${(props: Props) =>
    props.color === "dark" &&
    `
    color: var(--dark);
    border-color: var(--dark);
  `}

  ${(props: Props) =>
    props.color === "primary" &&
    `
    color: var(--white);
    border-color: var(--primary);
    background: var(--primary);
  `}

  ${(props: Props) =>
    props.color === "secondary" &&
    `
    background-color: var(--secondary)!important;
    font-weight: 500;
    .MuiCircularProgress-root {
      width: 28px !important;
      height: 28px !important;
    }
    * {
      stroke: white;
      color: white;
    }
  `}
  ${(props: Props) =>
    props.color === "highlighted" &&
    `
    background-color: var(--highlighted)!important;
    font-weight: 500;
    .MuiCircularProgress-root {
      width: 28px !important;
      height: 28px !important;
      margin-left: 10px;
    }
    * {
      stroke: white;
      color: white;
    }
  `}

  ${(props: Props) =>
    props.appearance === "button-outline" &&
    props.color === "inherit" &&
    `
    color: var(--white)!important;
    border-color: var(--white)!important;
    background: var(--transparent)!important;
    border: 2px solid var(--white)!important;
    margin-right: 15px!important;
  `}

  ${(props: Props) =>
    props.appearance === "outline-more" &&
    `
    position: relative;
    min-width: 180px!important;
    :after {
      content: "";
      transition: ease .3s all!important;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid var(--white);
      display: block;
      position: relative;
      right: 10px;
      bottom: 1px;
    }

    @media screen and (max-width: 680px) {
      right: -7px;
    }

    :hover, :focus {
      color: var(--secondary)!important;
      border-color: var(--secondary)!important;
      :after {
        right: 0;
        border-left-color: var(--secondary)!important;

        @media screen and (max-width: 480px) {
          right: -12px;
        }
      }
    }
  `}

  ${(props: Props) =>
    props.appearance === "outline-more" &&
    props.color === "inherit" &&
    `
    color: var(--white)!important;
    border-color: var(--white)!important;
    background: var(--transparent)!important;
    border: 2px solid var(--white)!important;
    line-height: 1.45!important;
    position: relative;

  `}

  
  ${(props: Props) =>
    props.appearance === "highlighted" &&
    `
    background-color: var(--highlighted)!important;
    color: var(--white)!important;
    position: relative;
    min-width: 180px!important;

    .MuiButton-label {
      padding-bottom: 5px;
    }

    :after {
      content: "";
      transition: ease .3s all!important;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid var(--white);
      display: block;
      position: relative;
      right: 10px;
      bottom: 1px;
    }

    :hover, :focus {
      color: var(--white)!important;
      :after {
        right: 0;
        border-left-color: var(--white)!important;
      }
    }
  `}

  ${(props: Props) =>
    props.appearance === "button-link" &&
    `
    color: var(--white)!important;
    background: var(--transparent)!important;
    position: absolute!important;
    right: 0;
    bottom: 10px;
    transition: ease .3s all!important;
    &:hover, &:focus {
      color: var(--secondary)!important;
    }
  `}

  ${(props: Props) => 
    props.appearance === "button-print" &&
    `
    @media print {
      visibility: hidden;
    }
    `}
  
  ${(props: Props) =>
    props.position === "left" &&
    `
    float: left;
  `}

  ${(props: Props) =>
    props.position === "right" &&
    `
    float: right;
  `}

  ${(props: Props) =>
    props.size === "small" &&
    `
  
  `}

  ${(props: Props) =>
    props.size === "medium" &&
    `
    padding: 10px;
    min-width: 120px;
  `}

  ${(props: Props) =>
    props.size === "large" &&
    `
    padding: 20px;
  `}
`;
