import styled from "styled-components";

type Props = {};

export const List = styled.span.attrs((props: Props) => ({}))`
  align-self: center;
  align-items: center;
  display: flex;

  @media screen and (max-width: 768px) {
    height: 20px;
    margin: 5px 0 0;
  }
`;

export const Item = styled.a.attrs((props: Props) => ({}))`
  color: var(--icon-background);
  padding: 5px 10px 5px 0;
  display: inline-flex;
  border-radius: 50px;
  &:hover,
  &:focus {
    opacity: 0.75;
  }

  @media screen and (max-width: 768px) {
    float: left;
    display: inline-block;
    padding: 3px;
  }
`;
