/**
 * This module only has a single request, for making the pre-registration.
 * Way later on, this can be removed, because it's only going to be used while the full release
 * is incomplete.
 */

import { PreRegisterForm } from "biohub-model";
import BiohubApi, {
  BiohubError,
  BiohubErrorCode,
  BiohubResponse,
  biohubUnit,
  BiohubUnit,
  extractBiohubError,
  extractBiohubErrorResponse,
  newBiohubFailure,
  newBiohubSuccess,
} from "./axios/BiohubApi";

async function preRegister(
  email: string,
  name: string,
  phoneNumber: string,
  company?: string,
  surveyAnswer?: string,
  marketResearchSurvey?: Array<string>
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    const preRegister: Omit<
      PreRegisterForm,
      "id" | "emailNotificationSent" | "smsNotificationSent"
    > = {
      name,
      email,
      cellPhoneNumber: phoneNumber,
      companyName: company,
      surveyAnswer: surveyAnswer,
      marketResearchSurvey: marketResearchSurvey,
    };
    await BiohubApi.post("/pre-registrations", {
      register: preRegister,
    });
    // This request doesn't return any data.
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    if (e.response?.data?.error === "EMAIL_ALREADY_REGISTERED") {
      return newBiohubFailure(BiohubErrorCode.PREREGISTER_EMAIL_ALREADY_REGISTERED);
    }

    if (e.response?.data?.error === "PHONE_NUMBER_ALREADY_REGISTERED") {
      return newBiohubFailure(BiohubErrorCode.PREREGISTER_PHONE_NUMBER_ALREADY_REGISTERED);
    }

    return {
      success: false,
      error: extractBiohubError(e),
    };
  }
}

async function readPreRegistrations(): Promise<BiohubResponse<Array<PreRegisterForm>>> {
  try {
    const result = await BiohubApi.get("/pre-registrations");
    const preRegistrations: Array<PreRegisterForm> = result.data[
      "preRegistrations"
    ] as Array<PreRegisterForm>;
    return newBiohubSuccess(preRegistrations);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

export default { preRegister, readPreRegistrations };
