import React from "react";
import { useIntl } from "react-intl";
import About from "../Tools/About";
import Networks from "../Tools/Social";
import Nav from "../Tools/Menu";
import Text from "../BasicComponents/Text";
import Icon from "../BasicComponents/Icon";
import Link from "../BasicComponents/Link";
import Flags from "../Tools/FlagLocalePicker";
import { Footer, Container, Copy, Infos, Info, Map, Mask } from "./styles";

interface Props {
  description: string;
}

export default (props: Props) => {
  const intl = useIntl();

  const menuItems = [
    { active: false, link: "", text: intl.formatMessage({ id: "menu.privacy" }) },
    { active: false, link: "", text: intl.formatMessage({ id: "menu.terms" }) },
  ];

  const socialItems = [
    { type: "linkedin", link: "https://www.linkedin.com/company/77182512" },
    { type: "facebook", link: "https://www.facebook.com/flybiohub" },
    { type: "instagram", link: "https://www.instagram.com/flybiohub" },
  ];

  return (
    <>
      <Footer appearance={"teaser"}>
        <Mask>
          <Map
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14673.453421676812!2d-45.7907324!3d-23.1569349!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xafef88839defabc7!2sNCB%20Sistemas%20Embarcados!5e0!3m2!1spt-BR!2sbr!4v1620074698683!5m2!1spt-BR!2sbr"
            loading="lazy"
          />
        </Mask>
        <Container>
          <About description={props.description} />
          <Infos>
            <Info>
              <Icon type="room_icon" />
              <Text size={"small"} text={intl.formatMessage({ id: "info.address" })} />
            </Info>
            <Info>
              <Icon type="drafts" />
              <Link to={`mailto:${intl.formatMessage({ id: "info.email" })}`}>
                <Text size={"small"} text={intl.formatMessage({ id: "info.email" })} />
              </Link>
            </Info>
          </Infos>
        </Container>
        <Copy>
          <Container>
            <Text size={"small"} text={intl.formatMessage({ id: "info.copyright" })} />
            <Networks socialItems={socialItems} />
            <Nav menuItems={menuItems} orientation={"horizontal"} color={"dark"} />
          </Container>
        </Copy>
        <Flags />
      </Footer>
    </>
  );
};
