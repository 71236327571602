import styled from "styled-components";
import Fields from "@material-ui/core/TextField";

type Props = {
  type?: string;
  placeholder?: string;
  required?: boolean;
};

export const Field = styled.span.attrs((props: Props) => ({}))`
  position: relative;
  display: inline-block;
`;

export const TextField = styled(Fields).attrs((props: Props) => ({}))`
  > div {
    border-radius: 50px;
    text-align: center;
    outline: none;
    margin: 0;
  }
  label {
    width: calc(100% - 35px);
    text-align: center;
  }
  label.MuiInputLabel-shrink {
    text-align: left;
    width: auto;
  }
`;
