/**
 * Español (es)
 */
const messagesMap: { [key: string]: string } = {
  //-- Page Information --//
  "info.pageTitle": "bioHUB - NCB",
  "info.slogan": "Preciso por Naturaleza",
  "info.title": "El primer y único HUB \nde tecnología para el \nControl Biológico de Precisión",
  "info.description": "¡Próximamente!",
  "info.newsletter": "Suscríbase a nuestro boletín",
  "info.about": "bioHUB por NCB - Sistemas Embarcados",
  "info.copyright": "2021 ⓒ bioHUB - NCB. Todos los derechos reservados.",
  "info.address":
    "Estrada Doutor Altino Bondensan, 500 PQTEC - CE1 - Sala 207 - Eugênio de Melo, São José dos Campos - SP, CEP: 147-016",
  "info.email": "contato@ncb.ind.br",
  "info.phone": "(11) 98371-9738",
  "info.terms":
    "Al aceptar el presente término, el usuario consiente y acepta que la empresa NCB, en adelante el Responsable, tome decisiones sobre el tratamiento de sus datos personales, así como para llevar a cabo el tratamiento de sus datos personales, involucrando operaciones como las referido a la recogida, producción, recepción, clasificación, uso, acceso, reproducción, transmisión, distribución, tratamiento, archivo, almacenamiento, eliminación, evaluación o control de información, modificación, comunicación, cesión, difusión o extracción. El Responsable está autorizado a compartir los datos personales del Titular con otros agentes del tratamiento de datos, de ser necesario para los fines enumerados en este término, sujeto a los principios y garantías establecidos por la Ley N ° 13.709.",
  "info.summary": "Resumen",
  "info.register.information": "Información de Registro",
  "info.register.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.legal.information": "Información del representante legal",
  "info.legal.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.plans.information": "Información del plan",
  "info.amount": "La cantidad",
  "info.plan": "Paquete",
  "info.mode": "Modo",
  "info.price": "Precio",
  "info.per.year": "Por Año",
  "info.total": "Total",
  "info.users": "Usuarios",
  "info.preregistrations": "Preinscripción",
  "info.preregistration": "Preinscripción",
  "info.preregistrations.viewdetails": "Estos son los datos de este prerregistro.",
  "info.contract": "Contrato",
  "info.add.new": "Ingrese los datos a continuación para agregar un nuevo usuario.",
  "info.clients": "Clientes",
  "info.termError": "Su mensaje no se pudo enviar en este momento.",
  "info.menuOptions": "Opciones de menú",

  "info.aboutTitle": "¿Qué es ",
  "info.aboutNCB": "NCB?",
  "info.aboutDescription":
    "bioHub es una plataforma digital holística que permite la gestión eficaz de plagas y enfermedades a través de drones y software con un enfoque en la inteligencia empresarial. \n\nSomos pioneros en el uso de control biológico de precisión.",
  "info.aboutVisionTitle": "Nuestra visión",
  "info.aboutVisionSub": "La realidad que se busca",
  "info.aboutVisionText":
    "Transformar o setor agrícola, promovendo a gestão de dados e a aplicação de tecnologia e inovação sustentável para a criação de estratégias e o uso assertivo do controle biológico. Em três anos, queremos que a Bi All seja a referência na simbiose entre agricultura, ciência e tecnologia, após ter operado três milhões de hectares de lavouras.",
  "info.aboutMissionTitle": "Nuestra misión",
  "info.aboutMissionSub": "La razón de ser",
  "info.aboutMissionText":
    "Catalizar la naturaleza a través de la digitalización de datos que permita la creación de estrategias de toma de decisiones, asegurando la mejor gestión de los recursos. Brindar soluciones en ecosistemas agrícolas mediante un control biológico de precisión.",
  "info.aboutValues": "Nuestros valores",
  "info.contactTitle": "Hable con nosotros",
  "info.contactDescription":
    "Complete sus datos en el formulario a continuación y espere. ¡Nos pondremos en contacto con usted lo antes posible!",
  "info.termTitle": "El plan bioMAPS",
  "info.coin": "€",
  "info.termPriceMonth": "por mes / por dispositivo",
  "info.termPriceYear": "por año / por dispositivo (15% de descuento)",
  "info.termSpecifications": "Especificaciones:",
  "info.termSpecificationsDesc":
    "É o primeiro planejador de missão da DRONES com foco no controle de precisão biológica. É possível planejar rotas de qualquer lugar do mundo através da nuvem e sincronizadas com um aplicativo móvel para fazer lançamentos de insumos biológicos 100% rastreados e controlados por GPS.",
  "info.termClassifications": "Calificaciones:",
  "info.termClassificationsDesc":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis curae cursus per, morbi neque viverra nam nascetur mauris metu consequat quis.",
  "info.termChoose": "Elija plan",
  "info.introducing": "Introduciendo",
  "info.cameraQuality": "Calidad de la cámara",
  "info.flightTime": "Tiempo de vuelo",
  "info.capacity": "Capacidad",
  "info.minutes": "minutos",
  "info.verificationTitle": "Verificacion de email",
  "info.verificationDescription":
    "La verificación requiere una dirección de correo electrónico válida.  nEnviaremos un código de confirmación a la dirección de correo electrónico que ingresó para iniciar el proceso de validación.  nSi la dirección que se proporciona a continuación es incorrecta, haga clic en el icono y cambie sus datos.",
  "info.emailAddress": "Dirección de correo electrónico",
  "info.verificationCodeDescription": "Ingrese el código enviado a su correo electrónico:",
  "info.confirmationCode": "Verification Code",
  "info.newPassword": "Nueva Contraseña",
  "info.newPasswordDescription":
    "Establezca una nueva contraseña para acceder a nuestra plataforma.",
  "info.newPasswordDetails": "Ingrese la nueva contraseña",
  "info.newPasswordConfirm": "Confirma tu nueva contraseña",

  "prod.biocoteDescription":
    "Es un liberador de copa biodegradable con masa de cothesia utilizado principalmente en el control poblacional de orugas. La caña de azúcar es el principal cultivo tratado. (Desarrollo exclusivo de Biocontrol). 100% operativo y comercial. Su versión de pasta única parasitada ha sido congelada porque no podemos continuar con las pruebas de campo, continuaremos a partir de enero de 2021.",
  "prod.usageSugarcane": "Caña de azúcar",
  "prod.usageForest": "Bosque",
  "prod.usageCotton": "Algodón",
  "prod.usageCorn": "Maíz",
  "prod.usageSoybeans": "Soja",

  //-- Landing Page --//
  "page.title": "Digitalice su campo, \nel primer paso hacia \nel futuro de la agricultura",
  "page.description":
    "bioHUB es una plataforma digital holística que permite el manejo efectivo de plagas y enfermedades a través de drones y software enfocado en inteligencia empresarial.",
  // Sections
  "section.about.title": "Nuestros productos",
  "section.about.category": "Software",
  "section.about.info.title": "Acerca de bioHUB",
  "section.about.info.subtitle": "Sistema de gestión de control biológico de precisión",
  "section.about.info.description":
    "Una plataforma en la nube integrada que ayuda a que toda la cadena de control biológico pueda monitorear plagas, liberar con precisión, generar informes y monitorear la toma de decisiones.",
  "section.biomaps.title": "Acerca de BioMAPS:",
  "section.biomaps.subtitle": "Planificador de misiones de vuelo",
  "section.biomaps.description":
    "El primer planificador de misiones para DRONES centrado en el control biológico de precisión. Es posible planificar rutas desde cualquier parte del mundo.",
  "section.products.category": "Hardware",
  "section.prices.category": "Precios",
  "section.prices.title": "El plan adecuado para su negocio",
  "section.prices.description":
    "Ofrecemos varios planes poderosos para exhibir su negocio y ser descubierto como un emprendedor creativo.",
  "section.clients.category": "Clientes",
  "section.clients.title": "Que tienen que decir nuestros clientes",
  "section.partners.category": "Socios",

  //-- Dashboard Information --//
  "dashboard.welcome": "Bienvenido de nuevo, \n",
  "dashboard.clients": "Clientes",
  "dashboard.users": "Utilisateurs",
  "dashboard.total.users": "Total de Usuarios",
  "dashboard.total.clients": "Total de Clientes",
  "dashboard.total.drones": "Drones Totales",
  "dashboard.total.projects": "Proyectos Totales",
  "dashboard.total.sales": "Ventas Totales",
  "dashboard.total.area": "Total de Hectáreas Cubiertas",
  "dashboard.total.yearMonthLegend": "Mes / Año",
  "dashboard.hectares.covered": "Hectáreas Cubiertas",
  "dashboard.new.company": "Nuevas empresas",
  "dashboard.flight.client": "Vuelos por cliente",
  "dashboard.coverage.graph": "Gráfico del área de cobertura",
  "dashboard.scale": "Escala",
  "dashboard.graphmodeview": "Vista por",
  "dashboard.filter": "Filtrar",
  "dashboard.time.30minutes": "30 minutos",
  "dashboard.time.1hour": "1 hora",
  "dashboard.time.8hours": "8 horas",
  "dashboard.time.1day": "1 día",
  "dashboard.time.1week": "1 semana",
  "dashboard.view.general": "-",
  "dashboard.view.profile": "Perfil",
  "dashboard.view.client": "Cliente",
  "dashboard.view.drone": "Drone",

  //-- Menu Information --//
  "menu.about": "Acerca de NCB",
  "menu.products": "Productos",
  "menu.pricing": "Precios",
  "menu.contact": "Contacto",
  "menu.privacy": "Política de privacidad",
  "menu.terms": "Términos y Condiciones",
  "menu.title": "Empresa",
  "menu.support": "Soporte",
  "menu.faq": "FAQ's",
  "menu.resources": "Recursos",
  "menu.why": "¿Por qué bioHUB?",
  "menu.home": "Comienzo",
  "menu.biomaps": "bioMAPS",
  "menu.profile": "Profile",
  "menu.plans": "Planes",
  "menu.settings": "Ajustes",
  "menu.open.project": "Proyecto Abierto",
  "menu.new.project": "Nuevo Proyecto",
  "menu.new.monitoring": "Nueva Monitorización",
  "menu.flight.monitoring": "Monitor de Vuelo",
  "menu.trap.monitoring": "Monitor de Trampa",
  "menu.biomapsInfo":
    "BioMAP visualiza las áreas donde se liberan los controladores biológicos a través del planificador de la misión.",
  "menu.licenses": "Licencias",
  "menu.traps": "Trampas",

  //-- Generic Actions --//
  "action.login": "Entrar",
  "action.signUp": "Suscribirse",
  "action.subscribe": "Firmar",
  "action.register": "Registrarse",
  "action.makeRegistration": "¡Haz tu preinscripción aquí!",
  "action.success": "Éxito",
  "action.error": "Perdon",
  "action.msgSuccess":
    "¡Su preinscripción fue exitosa en nuestra base de datos! \nPronto te enviaremos un mensaje ✓",
  "action.msgError":
    "¡Tuvimos un error al realizar tu preinscripción! Por favor, inténtelo de nuevo más tarde.",
  "action.back": "Volver",
  "action.getStarted": "Comienzo",
  "action.learnMore": "Sepa mas",
  "action.more": "Mas informaciones...",
  "action.keep.connected": "Mantente conectado",
  "action.login.message": "Ingrese sus datos para iniciar sesión",
  "action.login.msgError":
    "Error al iniciar sesión. Verifique sus datos o intente nuevamente en un momento.",
  "action.continue": "Continuar",
  "action.accept": "Estoy de acuerdo",
  "action.indicator": "Registrarse como: ",
  "action.signup.company": "Empresa",
  "action.signup.single": "Persona unica",
  "action.accept.signup.terms":
    "Acepto los términos generales de la ley de protección de datos LGPD.",
  "action.pay.now": "Pagar ahora",
  "action.add.new": "Agregar nuevo",
  "action.edit": "Cambiar",
  "action.edit.picture": "Cambiar foto",
  "action.remove": "Eliminar",
  "action.save": "Ahorrar",
  "action.cancel": "Cancelar",
  "action.undo": "Deshacer",
  "action.finish": "OK",
  "action.income": "Ingreso",
  "action.send": "Enviar",
  "action.confirm": "Confirmar",
  "action.sendVerification": "Envíe el código de verificación",
  "action.saveData": "Guardar datos",

  //-- Placeholders --//
  "placeholder.profile": "Perfil",
  "placeholder.email": "Su e-mail",
  "placeholder.name": "Su nombre",
  "placeholder.phone": "Su teléfono",
  "placeholder.company": "Su empresa",
  "placeholder.data.collect": "¿Cómo se enteró de NCB?",
  "placeholder.password": "La contraseña",
  "placeholder.search": "Buscar",
  "placeholder.iva": "IVA",
  "placeholder.company.name": "Nombre de empresa",
  "placeholder.zip": "Código Postal",
  "placeholder.address": "Calle",
  "placeholder.number": "Número",
  "placeholder.complement": "Complemento",
  "placeholder.country": "País",
  "placeholder.state.department": "Estado / Departamento",
  "placeholder.city": "Ciudad",
  "placeholder.comercial.phone": "Teléfono del trabajho",
  "placeholder.aviation.reg": "Registro de Aviación",
  "placeholder.agriculture.reg": "Registro de Agricultura",
  "placeholder.role": "Ocupación",
  "placeholder.document": "Documento",
  "placeholder.rg": "RG",
  "placeholder.crea": "CREA",
  "placeholder.ie": "I.E.",
  "placeholder.im": "I.M.",
  "placeholder.trade.name": "Nombre Comercial",
  "placeholder.type": "Tipo de usuario",
  "placeholder.contact": "Contacto",
  "placeholder.description": "Bio",
  "placeholder.message": "Mensaje",
  "placeholder.project": "Proyecto",
  "placeholder.area": "Área",
  "placeholder.aircraft": "Aeronave",

  "profile.selectedUser": "seleccionado",

  "profile.account": "Mi Cuenta",
  "profile.profile": "Mi Perfil",

  //-- Options --//
  "option.preferences.generalinfo": "Informaciones generales",
  "option.preferences.unitsystem": "Unidad de sistema",
  "option.preferences.twofactorauth": "Autenticación de dos factores",
  "option.preferences.sexagesimalcoordinates": "Usar coordenadas sexagesimales",
  "option.preferences.separatelybatteries": "Mostrar baterías por separado",
  "option.preferences.metric": "Métrico",
  "option.preferences.imperial": "Imperial",
  "option.search.engine": "Motores de búsqueda",
  "option.facebook": "Facebook",
  "option.linkedin": "Linkedin",
  "option.recommendation": "Recomendaciones",
  "option.email.marketing": "E-mail Marketing",
  "option.events": "Eventos",
  "option.others": "Otros",
  "option.which": "¿Cuales?",

  //-- Map --//
  "map.title": "Mapa",
  "map.defaultRegion": "ES",
  "map.editMode.addPolygon":
    "Toque en el mapa para agregar un nuevo punto. Mantenga un marcador presionado para moverlo.",
  "map.editMode.error.theePointsMinimum": "Por favor, indique al menos tres puntos.",
  "map.editMode.error.interesection":
    "El área dibujada tiene una o más intersecciones. Por favor, dibuja un polígono simple.",
  "map.removeArea": "¿Eliminar esta área?",
  "map.newArea": "Nueva área",
  "map.loadingList": "cargando...",
  "map.editPolygonPoints": "Editar puntos",
  "map.editAreaProperties": "Editar área",
  "map.deleteArea": "Eliminar área",
  "map.importAreas.importAreasAction": "Importar áreas",
  "map.newArea.createAreaModalTitle": "Crear nueva área",
  "map.newArea.importAreaModalTitle": "Importar áreas",
  "map.newArea.areaName": "Nombre del área",
  "map.newProject.title": "Crear proyecto",
  "map.newProject.projectName": "Nuevo proyecto",
  "map.newProject.optionalImportKml": "Importar archivo",
  "map.newProject.kmlFileChoose": "Abrir...",
  "map.newProject.releaser": "Liberador",
  "map.newProject.input": "Insumo",
  "map.newProject.releaseRate": "Tasa de liberación",
  "map.type.roadmap": "Mapa",
  "map.type.satellite": "Satélite",
  "map.type.hybrid": "Híbrido",
  "map.newProject.instantaneousReleaseRate": "Tasa de liberación instantánea",
  "map.areasAmountSing": "{amount} área",
  "map.areasAmountPl": "{amount} áreas",

  //-- Route planning --//
  "routePlanning.param.areaPadding": "Margen de la ruta",
  "routePlanning.param.flightHeight": "Altura de vuelo",
  "routePlanning.param.flightSpeed": "Velocidad de vuelo",
  "routePlanning.param.trackWidth": "Ancho de banda",
  "routePlanning.param.considerRelief": "Considere el relieve del terreno",

  "routePlanning.param.waypoint.height": "Altura",
  "routePlanning.param.waypoint.curveRadius": "Radio de curva",
  "routePlanning.param.waypoint.speed": "Velocidad",
  "routePlanning.param.waypoint.orientation": "Orientación",

  "routePlanning.param.waypoint.actions": "Comportamiento",
  "routePlanning.param.waypoint.action.enableEquipment": "Habilitar Equipo",
  "routePlanning.param.waypoint.action.disableEquipment": "Desactivar Equipo",
  "routePlanning.param.waypoint.action.none": "Ninguna",

  //-- LANGUAGES --//
  language: "Idioma",
  "language.pt": "Portugués brasileño",
  "language.es": "Español",
  "language.en": "Inglés",
  "language.fr": "Francés",
  "language.name": "Español",
  "language.region": "ES",
  "language.decimalSeparator": ",",

  //- Reports -//
  "report.issueDate": "Fecha de emisión:",
  "report.area": "Área",
  "report.plannedFlight": "Vuelo Planificado",
  "report.details": "Detalles",
  "report.dateHour": "Fecha y Hora:",
  "report.drone": "Drone:",
  "report.manufacturer": "Fabricante:",
  "report.anac": "ANAC:",
  "report.releasedProduct": "Producto Lanzado:",
  "report.releasing": "Libertador",
  "report.bandWidth": "Banda Ancha",
  "report.plannedSpeed": "Velocidad Planificada",
  "report.releaseRate": "Tasa de liberación",
  "report.realFlight": "Vuelo Real",
  "report.flightTime": "Tiempo de vuelo",
  "report.averageReleaseRate": "Tasa de liberación promedio",
  "report.averageSpeed": "Velocidad Media",
  "report.totalReleased": "Total Liberado",
  "report.totalArea": "Área Total",
  "report.traps": "Trampas",
  "report.serialNumber": "Numero de serie",
  "report.coveredHectares": "Hectáreas cubiertas",
  "report.dateMeasurament": "Fecha de la última medición",
  "report.specie": "Especies",
  "report.adultsNumber": "Número de Adultos",
  "report.noAvailable": "No hay artículos disponibles",
  "report.mapRecord": "Registro MAPA:",
  "report.contractor": "Contratista:",
  "report.property": "Propriedad:",
  "report.localization": "Localización:",
  "report.county": "Condado:",
  "report.uf": "UF:",
  "report.doc": "CNPJ/CPF:",
  "report.serviceType": "Tipo de servicio:",
  "report.product": "Producto",
  "report.formulation": "Formulación",
  "report.dosage": "Dosis",
  "report.toxicClass": "Clase tóxica",
  "report.adjuvant": "Auxiliar",
  "report.culture": "Cultura: ",
  "report.volume": "Volumen (litros ou kg/ha):",
  "report.others": "Otros: ",
  "report.agronomicalRecipe": "Receta agronómica n:",
  "report.issuedOn": "Emitido el:",
  "report.basicParameter": "Parámetros de aplicación básicos",
  "report.maxTemperature": "Temperatura Máx.=",
  "report.relativeUnitMin": "Unidad Relativa Min.=",
  "report.equipment": "Equipo=",
  "report.model": "Modelo=",
  "report.type": "Tipo=",
  "report.angle": "Ángulo=",
  "report.flightHeight": "Altura de vuelo=",
  "report.bandwidth": "Ancho de banda(m)=",
  "report.date": "Fecha",
  "report.signatureCREA": "Nombre, firma y CREA del profesional",
  "report.weatherConditions": "Condiciones climáticas en la aplicación",
  "report.morning": "Mañana",
  "report.evening": "Noche",
  "report.applicationStart": "Inicio de la aplicación: horas y finalización: horas",
  "report.initial": "Inicial",
  "report.final": "Final",
  "report.temperature": "Temperatura",
  "report.relativeHumidity": "Umid. Relativo",
  "report.aircraftPrefixe": "Prefijo - Aeronave:",
  "report.dgpsUse": "Uso do DGPS: Sí o No",
  "report.windSpeed": "Veloc. Viento",
  "report.trackCoordinates": "Seguimiento de coordenadas:",
  "report.observation": "Nota:",
  "report.signatureEng": "Nombre, firma y CREA del Ing. Agronomo:",

  // Pre-registration research
  "preregistration.interest.question":
    "¿Qué crees que es más importante en\nControl Biológico de Precisión?",
  "preregistration.interest.financial": "Financiero",
  "preregistration.interest.financial.cost": "Costo de operacion",
  "preregistration.interest.financial.savings": "Ahorro de insumos",
  "preregistration.interest.financial.pest": "Reducción de plagas",
  "preregistration.interest.financial.production": "Incremento de producción",

  "preregistration.interest.sustainability": "Sustentabilidad",
  "preregistration.interest.sustainability.carbonfootprint": "Reducción de la huella de carbono",
  "preregistration.interest.sustainability.pesticides": "Reducción de Plaguicidas",
  "preregistration.interest.sustainability.environment": "Medio ambiente",
  "preregistration.interest.sustainability.water": "Economía del agua",

  "preregistration.interest.reliability": "Confiabilidad",
  "preregistration.interest.reliability.precision": "Precisión",
  "preregistration.interest.reliability.releasingrate": "Tasa de liberación",
  "preregistration.interest.reliability.tracking": "Seguimiento de ruta",
  "preregistration.interest.reliability.coverage": "Cobertura de área",
};

export default messagesMap;
