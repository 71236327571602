import BiohubApi, {
  BiohubError,
  BiohubErrorCode,
  BiohubResponse,
  biohubUnit,
  BiohubUnit,
  clearBiohubAuthorizationToken,
  extractBiohubErrorResponse,
  newBiohubFailure,
  newBiohubSuccess,
  setBiohubAuthorizationToken,
} from "./axios/BiohubApi";

/**
 * Calls the login endpoint. If successful, the token will be automatically stored and used on all
 * subsequent requests.
 * @param user Username (probably e-mail address)
 * @param password Password in plaintext
 * @param automaticallySetToken If true, the authorization token will be automatically set for use
 * in all subsequent requests. This is false by default; you can manually set the token by calling
 * `setBiohubAuthorizationToken`.
 */
async function login(
  user: string,
  password: string,
  keepLoggedIn: boolean
): Promise<BiohubResponse<{ token: string; userId: string }>> {
  try {
    const response = await BiohubApi.post("/authenticate-user", {
      username: user,
      password: password,
      client: "biohub",
      keepLoggedIn: keepLoggedIn,
    });
    const token = response.data.token;
    // Check that the token was received as expected. These checks could be improved, probably.
    if (token && typeof token === "string" && token.length > 0) {
      // On successful login, set the token to axios.
      setBiohubAuthorizationToken(token);
      return newBiohubSuccess({
        token,
        userId: response.data.userId,
      });
    } else {
      return newBiohubFailure(BiohubErrorCode.LOGIN_TOKEN_NOT_RECEIVED);
    }
  } catch (e) {
    if (e.response?.data?.error === "UNRECOGNIZED_CLIENT") {
      return newBiohubFailure(BiohubErrorCode.LOGIN_UNRECOGNIZED_CLIENT);
    }
    if (e.response?.data?.error === "WRONG_EMAIL_OR_PASSWORD") {
      return newBiohubFailure(BiohubErrorCode.LOGIN_INCORRECT_USER_PASSWORD);
    }
    return extractBiohubErrorResponse(e);
  }
}

/// Deletes the locally stored token. Does not make a request, and always succeeds.
async function logout(): Promise<BiohubResponse<BiohubUnit>> {
  clearBiohubAuthorizationToken();
  return newBiohubSuccess(biohubUnit);
}

/**
 * Sends an email to the informed address for resetting the user's password.
 */
async function resetPassword(emailAddress: string): Promise<BiohubResponse<{}>> {
  // Not implemented yet.
  return newBiohubFailure(BiohubErrorCode.UNIMPLEMENTED);
}

export default { login, logout, resetPassword };
