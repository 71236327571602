import {
  DirectClient,
  Profile,
  Role,
  Company,
  IndirectClient,
  ProfilePreferences,
} from "biohub-model";
import { REHYDRATE } from "redux-persist/es/constants";
import { BiohubError } from "../../services/axios/BiohubApi";
import {
  PROFILE_READING,
  PROFILE_READ_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_REQUEST_ERROR,
  PROFILE_UPDATE_REQUEST_SUCCESS,
  PROFILE_RELOAD,
  PROFILE_RELOAD_ERROR,
  PROFILE_RELOAD_SUCCESS,
  PROFILE_ADITION,
  PROFILE_ADITION_ERROR,
  PROFILE_ADITION_SUCCESS,
  PROFILE_UPDATE_COMPANY,
  PROFILE_UPDATE_COMPANY_ERROR,
  PROFILE_UPDATE_COMPANY_SUCCESS,
  PROFILE_RELOAD_DIRECT_CLIENT,
  PROFILE_RELOAD_DIRECT_CLIENT_ERROR,
  PROFILE_RELOAD_DIRECT_CLIENT_SUCCESS,
  ADITION_INDIRECT_CLIENT,
  ADITION_INDIRECT_CLIENT_ERROR,
  ADITION_INDIRECT_CLIENT_SUCCESS,
  UPDATE_INDIRECT_CLIENT,
  UPDATE_INDIRECT_CLIENT_ERROR,
  UPDATE_INDIRECT_CLIENT_SUCCESS,
  READ_INDIRECT_CLIENTS,
  READ_INDIRECT_CLIENTS_ERROR,
  READ_INDIRECT_CLIENTS_SUCCESS,
  UPDATE_PROFILE_PREFERENCES,
  UPDATE_PROFILE_PREFERENCES_ERROR,
  UPDATE_PROFILE_PREFERENCES_SUCCESS,
  PROFILE_REMOTION,
  PROFILE_REMOTION_ERROR,
  PROFILE_REMOTION_SUCCESS,
  INDIRECT_CLIENT_REMOTION,
  INDIRECT_CLIENT_REMOTION_ERROR,
  INDIRECT_CLIENT_REMOTION_SUCCESS,
} from "../actions/profileActions";
import { SystemAction } from "../actions/systemActions";

export type ProfileState = {
  loading: boolean;
  error: BiohubError | null;
  userProfile: Profile | null;
  currentProfile: Profile | null;
  directClient: DirectClient | null;
  loadingIndirectClients: boolean;
  reloadingDirectClient: boolean;
  creatingProfile: boolean;
  creatingIndirectClient: boolean;
  updatingProfiles: boolean;
  updatingIndirecClient: boolean;
  reloadingProfiles: boolean;
  updatingCompany: boolean;
  indirectClients: Array<IndirectClient> | null;
  updatingPreferences: boolean;
  removingProfile: boolean;
  removingIndirectClient: boolean;
};

const INITIAL_STATE: ProfileState = {
  loading: false,
  error: null,
  userProfile: null,
  currentProfile: null,
  directClient: null,
  loadingIndirectClients: false,
  reloadingDirectClient: false,
  creatingProfile: false,
  creatingIndirectClient: false,
  updatingProfiles: false,
  updatingIndirecClient: false,
  reloadingProfiles: false,
  updatingCompany: false,
  indirectClients: null,
  updatingPreferences: false,
  removingProfile: false,
  removingIndirectClient: false,
};

export function findProfileIndex(profile: Partial<Profile>, profiles: Array<Profile>): number {
  return profiles.findIndex((i) => i.id === profile.id);
}

function _updateProfile(
  profile: Profile,
  updatedProfile: { id: string } & Partial<Profile>
): Profile {
  let externalInternalProfile;
  if (updatedProfile.role === Role.external) {
    externalInternalProfile = {
      ...updatedProfile,
      role: Role.external,
      address: undefined,
      document: undefined,
      landlineNumber: undefined,
      aviationNumber: undefined,
      agricultureNumber: undefined,
      rg: undefined,
      crea: undefined,
      preferences: undefined,
      directClientId: undefined,
    };
  } else {
    externalInternalProfile = {
      ...updatedProfile,
      indirectClientId: undefined,
    };
  }
  if (profile.id === updatedProfile.id) {
    // I don't know why, but to update a objet with a partial objet I find that way to work
    for (const update in externalInternalProfile) {
      const key = update as keyof Profile;
      if (externalInternalProfile[key] !== undefined) {
        (profile[key] as any) = externalInternalProfile[key];
      }
    }
  }

  return profile;
}

function _completeProfileUpdate(
  updatedProfile: { id: string } & Partial<Profile>,
  userProfile: Profile,
  currentProfile: Profile,
  profiles: Array<Profile>
): { userProfile: Profile; currentProfile: Profile; profiles: Array<Profile> } {
  if (userProfile.id === updatedProfile.id) {
    userProfile = _updateProfile(userProfile, updatedProfile);
  }

  if (currentProfile.id === updatedProfile.id) {
    currentProfile = _updateProfile(currentProfile, updatedProfile);
  }

  const newListProfiles: Array<Profile> = profiles.map(function (profile: Profile) {
    if (profile.id === updatedProfile.id) {
      profile = _updateProfile(profile, updatedProfile);
    }
    return profile;
  });

  return {
    userProfile: userProfile,
    currentProfile: currentProfile,
    profiles: newListProfiles,
  };
}

function _completeIndirectClientUpdate(
  updatedIndirectClient: { id: string } & Partial<IndirectClient>,
  indirectClients: Array<IndirectClient>
): Array<IndirectClient> {
  // I don't know why, but to update a objet with a partial objet I find that way to work
  const updatedList: Array<IndirectClient> = indirectClients.map(
    (indirectClient: IndirectClient) => {
      if (updatedIndirectClient.id === indirectClient.id) {
        for (const update in updatedIndirectClient) {
          const key = update as keyof IndirectClient;
          if (updatedIndirectClient[key] !== undefined) {
            (indirectClient[key] as any) = updatedIndirectClient[key];
          }
        }
      }
      return indirectClient;
    }
  );
  return updatedList;
}

function _updateProfilePreferences(
  profile: Profile,
  updatedPreferences: { id: string } & Partial<ProfilePreferences>
): Profile {
  if (profile.id === updatedPreferences.id && profile.role !== Role.external) {
    let preferences: ProfilePreferences = profile.preferences;
    // I don't know why, but to update a objet with a partial objet I find that way to work
    for (const update in updatedPreferences) {
      const key = update as keyof ProfilePreferences;
      if (updatedPreferences[key] !== undefined) {
        (preferences[key] as any) = updatedPreferences[key];
      }
    }
    profile.preferences = preferences;
  }
  return profile;
}

function _completeProfilePreferencesUpdate(
  updatedPreferences: { id: string } & Partial<ProfilePreferences>,
  userProfile: Profile,
  currentProfile: Profile,
  profiles: Array<Profile>
): { userProfile: Profile; currentProfile: Profile; profiles: Array<Profile> } {
  if (userProfile.id === updatedPreferences.id) {
    userProfile = _updateProfilePreferences(userProfile, updatedPreferences);
  }

  if (currentProfile.id === updatedPreferences.id) {
    currentProfile = _updateProfilePreferences(currentProfile, updatedPreferences);
  }

  const newListProfiles: Array<Profile> = profiles.map(function (profile: Profile) {
    if (profile.id === updatedPreferences.id) {
      profile = _updateProfilePreferences(profile, updatedPreferences);
    }
    return profile;
  });

  return {
    userProfile: userProfile,
    currentProfile: currentProfile,
    profiles: newListProfiles,
  };
}

function _completeRemoveProfile(
  profileId: string,
  currentProfile: Profile,
  userProfile: Profile,
  profiles: Array<Profile>
): { currentProfile: Profile; profiles: Array<Profile> } {
  if (currentProfile.id === profileId) {
    currentProfile = userProfile;
  }
  const newListProfiles: Array<Profile> = [];
  profiles.forEach((profile: Profile) => {
    if (profile.id !== profileId) {
      newListProfiles.push(profile);
    }
  });

  return {
    currentProfile: currentProfile,
    profiles: newListProfiles,
  };
}

function _completeRemoveIndirectClient(
  indirectClientId: string,
  indirectClients: Array<IndirectClient>
): Array<IndirectClient> {
  let newListIndirectClients: Array<IndirectClient> = [];

  indirectClients.forEach((indirectClient: IndirectClient) => {
    if (indirectClient.id !== indirectClientId) {
      newListIndirectClients.push(indirectClient);
    }
  });

  return newListIndirectClients;
}

export function profileReducer(state = INITIAL_STATE, action: SystemAction): ProfileState {
  let profiles: Array<Profile> | null = null;
  let directClient: DirectClient | null = state.directClient;

  let updatingProfiles: { userProfile: Profile; currentProfile: Profile; profiles: Array<Profile> };

  let indirectClients: Array<IndirectClient>;

  let updatedProfile: { id: string } & Partial<Profile>;
  if (state.indirectClients === null) {
    indirectClients = [];
  } else {
    indirectClients = state.indirectClients as Array<IndirectClient>;
  }

  switch (action.type) {
    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.profile,
          }
        : state;
    case PROFILE_READING:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_READ_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfile: action.payload.profile,
        currentProfile: action.payload.profile,
        directClient: action.payload.directClient,
        error: null,
      };

    case PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        updatingProfiles: true,
      };
    case PROFILE_UPDATE_REQUEST_ERROR:
      return {
        ...state,
        updatingProfiles: false,
        error: action.payload.error,
      };
    case PROFILE_UPDATE_REQUEST_SUCCESS:
      updatedProfile = action.payload.profile;

      updatingProfiles = _completeProfileUpdate(
        updatedProfile,
        state.userProfile as Profile,
        state.currentProfile as Profile,
        state.directClient != null ? state.directClient.profiles : []
      );

      if (directClient !== null) {
        directClient.profiles = updatingProfiles.profiles;
      }
      return {
        ...state,
        directClient: directClient,
        userProfile: updatingProfiles.userProfile,
        currentProfile: updatingProfiles.currentProfile,
        updatingProfiles: false,
        error: null,
      };

    case PROFILE_RELOAD:
      return {
        ...state,
        reloadingProfiles: true,
      };
    case PROFILE_RELOAD_ERROR:
      return {
        ...state,
        reloadingProfiles: false,
        error: action.payload.error,
      };
    case PROFILE_RELOAD_SUCCESS:
      const reloadedProfile = action.payload.profile;

      updatingProfiles = _completeProfileUpdate(
        reloadedProfile,
        state.userProfile as Profile,
        state.currentProfile as Profile,
        state.directClient != null ? state.directClient.profiles : []
      );

      if (directClient !== null) {
        directClient.profiles = updatingProfiles.profiles;
      }

      return {
        ...state,
        userProfile: updatingProfiles.userProfile,
        currentProfile: updatingProfiles.currentProfile,
        directClient: directClient,
        reloadingProfiles: false,
        error: null,
      };

    case PROFILE_ADITION:
      return {
        ...state,
        creatingProfile: true,
      };
    case PROFILE_ADITION_ERROR:
      return {
        ...state,
        creatingProfile: false,
        error: action.payload.error,
      };
    case PROFILE_ADITION_SUCCESS:
      if (directClient !== null) {
        profiles = directClient.profiles;
        profiles.push(action.payload.profile);

        directClient.profiles = profiles;
      }
      return {
        ...state,
        directClient: directClient,
        creatingProfile: false,
        error: null,
      };

    case PROFILE_UPDATE_COMPANY:
      return {
        ...state,
        updatingCompany: true,
      };
    case PROFILE_UPDATE_COMPANY_ERROR:
      return {
        ...state,
        updatingCompany: false,
        error: action.payload.error,
      };
    case PROFILE_UPDATE_COMPANY_SUCCESS:
      // TODO: Check if it will work
      if (directClient !== null) {
        directClient.company = {
          ...directClient.company,
          ...action.payload.company,
        } as any as Company;
      }
      return {
        ...state,
        updatingCompany: false,
        error: null,
        directClient: directClient,
      };

    case PROFILE_RELOAD_DIRECT_CLIENT:
      return {
        ...state,
        reloadingDirectClient: true,
      };
    case PROFILE_RELOAD_DIRECT_CLIENT_ERROR:
      return {
        ...state,
        reloadingDirectClient: false,
        error: action.payload.error,
      };
    case PROFILE_RELOAD_DIRECT_CLIENT_SUCCESS:
      return {
        ...state,
        reloadingDirectClient: false,
        error: null,
        directClient: action.payload.directClient,
      };

    case READ_INDIRECT_CLIENTS:
      return {
        ...state,
        loadingIndirectClients: true,
      };
    case READ_INDIRECT_CLIENTS_ERROR:
      return {
        ...state,
        loadingIndirectClients: false,
        error: action.payload.error,
      };
    case READ_INDIRECT_CLIENTS_SUCCESS:
      return {
        ...state,
        loadingIndirectClients: false,
        error: null,
        indirectClients: action.payload.indirectClients,
      };

    case ADITION_INDIRECT_CLIENT:
      return {
        ...state,
        creatingIndirectClient: true,
      };
    case ADITION_INDIRECT_CLIENT_ERROR:
      return {
        ...state,
        creatingIndirectClient: false,
        error: action.payload.error,
      };
    case ADITION_INDIRECT_CLIENT_SUCCESS:
      indirectClients.push(action.payload.indirectClient);
      return {
        ...state,
        creatingIndirectClient: false,
        error: null,
        indirectClients: indirectClients,
      };

    case UPDATE_INDIRECT_CLIENT:
      return {
        ...state,
        updatingIndirecClient: true,
      };
    case UPDATE_INDIRECT_CLIENT_ERROR:
      return {
        ...state,
        updatingIndirecClient: false,
        error: action.payload.error,
      };
    case UPDATE_INDIRECT_CLIENT_SUCCESS:
      return {
        ...state,
        updatingIndirecClient: false,
        error: null,
        indirectClients: _completeIndirectClientUpdate(
          action.payload.indirectClient,
          indirectClients
        ),
      };

    case UPDATE_PROFILE_PREFERENCES:
      return {
        ...state,
        updatingPreferences: true,
      };
    case UPDATE_PROFILE_PREFERENCES_ERROR:
      return {
        ...state,
        updatingPreferences: false,
        error: action.payload.error,
      };
    case UPDATE_PROFILE_PREFERENCES_SUCCESS:
      const updatedPrerefences: { id: string } & Partial<ProfilePreferences> =
        action.payload.profilePrefences;
      updatingProfiles = _completeProfilePreferencesUpdate(
        updatedPrerefences,
        state.userProfile as Profile,
        state.currentProfile as Profile,
        state.directClient != null ? state.directClient.profiles : []
      );
      if (directClient !== null) {
        directClient.profiles = updatingProfiles.profiles;
      }

      return {
        ...state,
        updatingPreferences: false,
        userProfile: updatingProfiles.userProfile,
        currentProfile: updatingProfiles.currentProfile,
        directClient: directClient,
        error: null,
      };

    case PROFILE_REMOTION:
      return {
        ...state,
        removingProfile: true,
      };
    case PROFILE_REMOTION_ERROR:
      return {
        ...state,
        removingProfile: false,
        error: action.payload.error,
      };
    case PROFILE_REMOTION_SUCCESS:
      const profileRemotionValues = _completeRemoveProfile(
        action.payload.profileId,
        state.currentProfile as Profile,
        state.userProfile as Profile,
        state.directClient !== null ? state.directClient.profiles : []
      );

      if (directClient !== null) {
        directClient.profiles = profileRemotionValues.profiles;
      }

      return {
        ...state,
        removingProfile: false,
        error: null,
        directClient: directClient,
        currentProfile: profileRemotionValues.currentProfile,
      };

    case INDIRECT_CLIENT_REMOTION:
      return {
        ...state,
        removingIndirectClient: true,
      };
    case INDIRECT_CLIENT_REMOTION_ERROR:
      return {
        ...state,
        removingIndirectClient: false,
        error: action.payload.error,
      };
    case INDIRECT_CLIENT_REMOTION_SUCCESS:
      return {
        ...state,
        removingIndirectClient: false,
        indirectClients: _completeRemoveIndirectClient(
          action.payload.indirectClientId,
          state.indirectClients as Array<IndirectClient>
        ),
      };
    default:
      return state;
  }
}
